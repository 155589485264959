import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth } from '../context/AuthContext';
import ErrorFallback from './errors/ErrorFallback';
import marketplaceService from '../services/marketplaceService';
import alertNotification from '../utils/alertNotification';
import SupplierOrdersTable from '../components/marketplaceOrders/supplierOrders/SupplierOrdersTable';
import StatusSection from '../components/marketplaceOrders/StatusSection';
import { classNames } from '../utils/conditionalClasses';
import Pagination from '../components/common/Pagination';
import OrderFilterForm from '../components/marketplaceOrders/OrderFilterForm';
import StatusChangeModal from '../components/marketplaceOrders/StatusChangeModal';
import { useSearchParams } from 'react-router-dom';
import { EtsyOrder, parseBoolean } from './MarketplaceOrders';
import ExportXLS from '../components/marketplace/ExportXLS';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import LoadingComponent from '../components/common/LoadingComponent';
import httpClient from '../utils/httpClient';
import { DocumentDownload } from 'iconsax-react';
import LabelCreationModal from '../components/marketplaceOrders/LabelCreationModal';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';

const MarketplaceSupplierOrders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const printRef = useRef<HTMLTableElement>(null);
  const { session } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  let temp = Object.fromEntries([...searchParams]);
  const [queryString, setQueryString] = useState('');
  const [queryParams, setQueryParams] = useState<any>({
    shop: temp?.shop || '',
    country: temp?.country || '',
    startDate: temp?.startDate || ('' as any),
    endDate: temp?.endDate || ('' as any),
    isGift: parseBoolean(temp?.isGift) || '',
    isGiftWrap: parseBoolean(temp?.isGiftWrap) || '',
    customer: temp?.customer || '',
    label: temp?.label || '',
    orderId: temp?.orderId || '',
  });
  const [shops, setShops] = useState<any[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const memoizedOrders = useMemo(() => orders, [orders]);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [inputPage, setInputPage] = useState<string | number>(1);
  const [totalPage, setTotalPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [balance, setBalance] = useState<number>();
  const [labelCounts, setLabelCounts] = useState();
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);
  const [labelCreationStatus, setLabelCreationStatus] = useState<any[]>([]);

  const getSupplierOrders = async () => {
    setIsLoading(true);

    try {
      const res = await marketplaceService.getSupplierOrders(page, queryParams);
      const groupBySellerOrderId = (orders: any) => {
        // İlk olarak sellerOrderId'ye göre gruplama yapmak için bir nesne oluşturuyoruz
        const groupedBySellerOrderId = orders.reduce((acc: any, order: any) => {
          order.seller_order_items.forEach((item: any) => {
            const { sellerOrderId } = item;

            // Eğer sellerOrderId daha önce eklenmemişse, yeni bir array başlat
            if (!acc[sellerOrderId]) {
              acc[sellerOrderId] = { ...order, seller_order_items: [item] };
            } else {
              // Daha önce eklenmişse, mevcut seller_order_items dizisine ekle
              acc[sellerOrderId].seller_order_items.push(item);
            }
          });

          return acc;
        }, {});

        // groupedBySellerOrderId nesnesindeki değerleri bir array'e dönüştür
        const groupedArray = Object.values(groupedBySellerOrderId);

        return groupedArray;
      };

      // const groupedById = res.data.data.reduce((acc: any, current: any) => {
      //   const { id, seller_order_items } = current;

      //   if (!acc[id]) {
      //     acc[id] = { ...current, seller_order_items: [...seller_order_items] };
      //   } else {
      //     acc[id].seller_order_items.push(...seller_order_items);
      //   }

      //   return acc;
      // }, {});
      setOrders(
        groupBySellerOrderId(res.data.data).sort(
          (a: any, b: any) =>
            (new Date(b.orderDate) as any) - (new Date(a.orderDate) as any)
        ) as EtsyOrder[]
      );
      setLabelCounts(res.data.labelCount);
      setTotalPage(res.data.totalPageCount);
      return groupBySellerOrderId(res.data.data);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getWalletBalance = async () => {
    try {
      const res = await marketplaceService.getWalletBalance();

      setBalance(Number(res.data.balance));
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const getShops = async () => {
    try {
      const res = await httpClient.get('/api/v1/marketplace/supplier/stores');
      setShops(res.data.data);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  const downloadFile = async (path: string) => {
    try {
      const response = await axios({
        url: `https://api.rexven.com/${path}`,
        method: 'GET',
        responseType: 'blob', // Blob olarak döndür
      });
      // Blob’u al ve bir indirme bağlantısı oluştur
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'labels.zip'); // Dosya ismini burada belirle
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      setLabelCreationStatus([]);
    } catch (err) {
      console.error('Dosya indirilemedi:', err);
      setLabelCreationStatus([]);
    }
  };
  const getShipstationLabel = async (orderId: number) => {
    await httpClient.get(
      `/api/v1/marketplace/shipstation-label?platformOrderId=${orderId}`
    );
  };

  const getShipentegraLabel = async (orderId: number) => {
    await httpClient.get(
      `/api/v1/marketplace/shipentegra-label?platformOrderId=${orderId}`
    );
  };
  const downlaodLabels = async () => {
    // Initialize label creation status
    const initialStatus = selectedOrders.map((order: any) => ({
      orderId: order.seller_order_items[0].seller_order.platformOrderId,
      status: 'pending',
      message: 'Label creation in progress',
    }));
    setLabelCreationStatus(initialStatus);
    setIsLabelModalOpen(true);

    let successfulLabelsIds: any = [];

    // Process each selected order one by one

    // for (let i = 0; i < selectedOrders.length; i++) {
    await selectedOrders.reduce(async (promise, order) => {
      await promise;
      // const order = selectedOrders[i];
      try {
        let labelType = order.serviceCode.includes('shipentegra')
          ? 'shipentegra'
          : 'shipstation';
        // Determine which label creation function to call
        if (labelType === 'shipstation') {
          await getShipstationLabel(
            order.seller_order_items[0].seller_order.platformOrderId
          );
        } else if (labelType === 'shipentegra') {
          await getShipentegraLabel(
            order.seller_order_items[0].seller_order.platformOrderId
          );
        }

        // Update labelCreationStatus
        setLabelCreationStatus((prevStatus) =>
          prevStatus.map((statusItem) => {
            if (
              statusItem.orderId ===
              order.seller_order_items[0].seller_order.platformOrderId
            ) {
              return {
                ...statusItem,
                status: 'success',
                message: 'Label created successfully',
              };
            }
            return statusItem;
          })
        );

        // Collect the label path

        successfulLabelsIds.push(
          order.seller_order_items[0].seller_order.platformOrderId
        );
      } catch (error: any) {
        console.log('error');
        // Update labelCreationStatus
        setLabelCreationStatus((prevStatus) =>
          prevStatus.map((statusItem) => {
            if (
              statusItem.orderId ===
              order.seller_order_items[0].seller_order.platformOrderId
            ) {
              return {
                ...statusItem,
                status: 'error',
                message:
                  error.response?.data?.message || 'Label creation failed',
              };
            }
            return statusItem;
          })
        );
      }
    }, Promise.resolve());
  
    let updatedSelectedOrders:any = await getSupplierOrders();

    // Collect the label path
    //  if (order.seller_order_items[0].seller_order.labelPdf) {
    //   successfulLabelsPaths.push(
    //     order.seller_order_items[0].seller_order.labelPdf
    //   );
    // }
    // After all labels are processed, download the successful labels
    let successfulLabelsPaths: any = [];
    if (
      successfulLabelsIds.length > 0 &&
      updatedSelectedOrders &&
      updatedSelectedOrders.length > 0
    ) {
      try {
        console.log(successfulLabelsIds);
        console.log(selectedOrders);

        successfulLabelsIds.map((item: any) => {
          successfulLabelsPaths.push(
            updatedSelectedOrders.find(
              (order: any) =>
                order.seller_order_items[0].seller_order.platformOrderId ===
                item
            ).seller_order_items[0].seller_order.labelPdf
          );
        });

        const res = await marketplaceService.downloadLabels(
          successfulLabelsPaths
        );
        downloadFile(res.data);
      } catch (error: any) {
        alertNotification('error', error.response.data.message);
        setLabelCreationStatus([]);
      }
    } else {
      setLabelCreationStatus([]);
      alertNotification('warning', 'No labels were created successfully.');
    }
    setIsLabelModalOpen(false);
  };
  // const downlaodLabels = async () => {
  //   const paths: any = [];
  //   // Here open a modal
  //   // List all seller orders with order id and label created info
  //   // than create labels for each seller order
  //   // if label created successfully change row color to green and say label created
  //   // if label not created change row color to red and say label not created
  //   // when all labels created download file with successfull labels
  //   selectedOrders.map((item: any) => {
  //     if (item.seller_order_items[0].seller_order.labelPdf) {
  //       paths.push(item.seller_order_items[0].seller_order.labelPdf);
  //     }
  //   });
  //   console.log('paths', paths);
  //   try {
  //     const res = await marketplaceService.downloadLabels(paths);
  //     downloadFile(res.data);
  //   } catch (error: any) {
  //     alertNotification('error', error.response.data.message);
  //   }
  // };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'Sipariş Tablosu',
    onAfterPrint: () => {
      // notificaiton
      alertNotification('success', 'Tablo PDF olarak indirildi');
    },
  });
  useEffect(() => {
    getShops();
    getSupplierOrders();
    getWalletBalance();
  }, [page, queryString, queryParams]);

  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <OrderFilterForm
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          shops={shops}
          page={page}
          setInputPage={setInputPage}
          setQueryString={setQueryString}
          setPage={setPage}
        />
        <div className="bg-white">
          <div className="w-full px-4 my-2 flex justify-between items-center">
            {session?.isSupplier && (
              <button
                disabled={selectedOrders.length === 0}
                className={classNames(
                  selectedOrders.length === 0
                    ? 'text-[#4B5563] bg-[#D1D5DB]'
                    : 'text-white bg-[#1D4ED8]',
                  ' text-xs 2xl:text-sm px-4 py-2 rounded-lg '
                )}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Durum Değiştir{' '}
                {selectedOrders.length > 0 && (
                  <span className="text-sm">({selectedOrders.length})</span>
                )}
              </button>
            )}
            <div className="flex space-x-2 items-center">
              <button
                className={classNames(
                  selectedOrders.length === 0
                    ? 'border-[#4B5563] border-2 text-[#4B5563]'
                    : 'border-[#E3211E] border-2 text-[#E3211E] ',
                  'flex space-x-1 items-center rounded-full py-1 px-4'
                )}
                disabled={selectedOrders.length === 0}
                onClick={downlaodLabels}
              >
                <DocumentDownload size="18" color="#E3211E" />
                {isPdfLoading ? <LoadingComponent /> : <p>Etiketleri İndir</p>}
              </button>
           
          <button
            className="border-[#059669] border-2 text-[#059669] flex space-x-1 items-center rounded-full py-1 px-4"
            onClick={handlePrint}
          >
            PDF İndir
          </button>
     
              {/* <ExportXLS queryParams={queryParams} /> */}
              <div className="bg-[#047857] py-1 px-4 rounded-full border border-[#047857]">
                <p className="text-white font-medium">
                  Bakiye: ${Number(balance).toFixed(1)}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <StatusSection
              labelCounts={labelCounts}
              setQueryParams={setQueryParams}
              setPage={setPage}
              setQueryString={setQueryString}
              setInputPage={setInputPage}
            />
          </div>
        </div>
        {/* <div className="flex flex-col flex-shrink-0 bg-white rounded-2xl p-5 shadow-lg mt-2 sm:p-4 relative"> */}
        <SupplierOrdersTable
          ref={printRef}
          setIsModalOpen={setIsModalOpen}
          setSelectedOrders={setSelectedOrders}
          selectedOrders={selectedOrders}
          isLoading={isLoading}
          getSupplierOrders={getSupplierOrders}
          orders={memoizedOrders}
          page={page}
        />
        {/* </div> */}
      </ErrorBoundary>
      <Pagination
        page={page}
        setPage={setPage}
        totalPageCount={totalPage}
        inputPage={inputPage}
        setInputPage={setInputPage}
        queryString={queryString}
      />
      {selectedOrders.length > 0 && isModalOpen && (
        <StatusChangeModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          getSupplierOrders={getSupplierOrders}
          // refetchData={refetchData}
        />
      )}
      <LabelCreationModal
        isOpen={isLabelModalOpen}
        onClose={() => setIsLabelModalOpen(false)}
        labelCreationStatus={labelCreationStatus}
      />
    </div>
  );
};

export default MarketplaceSupplierOrders;
