import { Warning2 } from 'iconsax-react';
import React from 'react';
import { useAuth } from '../../context/AuthContext';
interface Props {
  warning: string;
}

const WarningText: React.FC<Props> = ({ warning }) => {
  const { session } = useAuth();
  if (warning === 'exceed' && session?.isSupplier) {
    return (
      <div className="flex justify-center space-x-2 items-center animate-pulse z-0 bg-[#7C3AED] text-[#F9FAFB] px-2 w-full max-w-[200px] py-[2px] text-center text-xs border-2 rounded-md border-[#7C3AED]">
        <Warning2 size={18} color="white" />
        <p>Sipariş Gecikti</p>
      </div>
    );
  } else if (warning === 'exceed' && !session?.isSupplier) {
    return null;
  }
  if (warning === 'beforeOne') {
    return (
      <div className="flex justify-center space-x-2 animate-pulse bg-[#EA580C] z-0 text-[#F9FAFB] px-2 py-[2px] w-full max-w-[200px] text-center text-xs border-2 rounded-md border-[#EA580C]">
        <Warning2 size={18} color="white" /> <p>Son 1 Gün</p>
      </div>
    );
  }
  if (warning === 'Yeniden Gönderim') {
    return (
      <div className="flex justify-center space-x-2 animate-pulse bg-[#1D4ED8] z-0 text-[#F9FAFB] px-2 py-[2px] w-full max-w-[200px] text-center text-xs border-2 rounded-md border-[#1D4ED8]">
        <Warning2 size={18} color="white" /> <p>{warning}</p>
      </div>
    );
  }

  if (warning === '') {
    return null;
  }

  return (
    <div className="flex justify-center space-x-2 animate-pulse bg-rexRed z-0 text-[#F9FAFB] px-2 w-full max-w-[200px] py-[2px] text-center text-xs border-2 rounded-md border-rexRed">
      <Warning2 size={18} color="white" />

      <p>Son Gün</p>
    </div>
  );
};

export default WarningText;
