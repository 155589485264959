import React from 'react';
import DemoShopInfoCardWrapper from '../components/marketplaceDemo/DemoShopInfoCards';
import DemoMyProductsTable from '../components/marketplaceDemo/DemoMyProductsTable';
import DemoMyProductsCard from '../components/marketplaceDemo/DemoMyProductsCard';
import CampDiscountModal from '../components/discountModal/CampDiscountModal';
import CampDiscountWarn from '../components/discountModal/CampDiscountWarn';

const products = [
  {
    id: '111924',
    title:
      'Harris Yard Sign cats 2024,Kamala Harris Yard Sign,Harris Walz For President,Kamala Harris Lawn Sign,Vote Democrat Lawn Sign,Anti Trump Sign',
    images: [
      'https://i.etsystatic.com/25457903/r/il/10bd9b/6280747830/il_fullxfull.6280747830_hrap.jpg',
    ],
    is_image_saved: true,
    product_id: '2832283',
    sku: 'YARD14',
    seller_shop_id: '30',
    order_count: 39,
    productRevenue: 1700.8187048832272,
    averageProductSalesPrice: 43.61073602264685,
  },
  {
    id: '111517',
    title:
      'Comma La, kamala harris 2024,harris walz 2024,anti trump sign,harris for president,outdoor sign,2024 Election Kamala,kamala harris yard sign',
    images: [
      'https://i.etsystatic.com/25457903/r/il/0c9096/6320701119/il_fullxfull.6320701119_nvvh.jpg',
    ],
    is_image_saved: true,
    product_id: '2831800',
    sku: 'YARD05',
    seller_shop_id: '30',
    order_count: 23,
    productRevenue: 680.4167415730337,
    averageProductSalesPrice: 29.5833365901319,
  },
  {
    id: '140125',
    title:
      'Childless Cat Lady Yard Signs,Halloween Yard Sign,Harris Walz Yard Sign,Kamala 2024 Yard Sign,Kamala President Yard Sign Cats for 2024',
    images: [
      'https://i.etsystatic.com/25457903/r/il/9f1dac/6345424461/il_fullxfull.6345424461_sytk.jpg',
    ],
    is_image_saved: true,
    product_id: '2933218',
    sku: 'YARD34',
    seller_shop_id: '30',
    order_count: 18,
    productRevenue: 559.16,
    averageProductSalesPrice: 31.064444444444444,
  },
  {
    id: '111518',
    title:
      'Harris Walz 2024 Yard Sign,We Are Not Going Back Democrat Sign,Yard Signs Kamala Harris Political Yard Sign,Election 2024 Signs,Vote Signs',
    images: [
      'https://i.etsystatic.com/25457903/r/il/7aec42/6318861773/il_fullxfull.6318861773_1b2a.jpg',
    ],
    is_image_saved: true,
    product_id: '2831407',
    sku: 'YARD38',
    seller_shop_id: '30',
    order_count: 12,
    productRevenue: 353.4375111441308,
    averageProductSalesPrice: 29.453125928677565,
  },
  {
    id: '146062',
    title:
      'Black cat yard sign,cats for kamala,Childless cat lady yard sign,Cat ladies for kamala,Harris Supporter Sign, Democrat Lawn Sign,Yard signs',
    images: [
      'https://i.etsystatic.com/25457903/r/il/510bb4/6371827341/il_fullxfull.6371827341_7k60.jpg',
    ],
    is_image_saved: true,
    product_id: '3281551',
    sku: 'YARD90',
    seller_shop_id: '30',
    order_count: 11,
    productRevenue: 466.92,
    averageProductSalesPrice: 42.447272727272725,
  },
  {
    id: '140014',
    title:
      'Colin Allred US Senate Yard Sign,Democrat Yard Sign US Senate Election Colin Allred 2024 Race Red White Blue Yard Sign,Harris Walz Yard Sign',
    images: [
      'https://i.etsystatic.com/25457903/r/il/ba663b/6291558088/il_fullxfull.6291558088_1buc.jpg',
    ],
    is_image_saved: true,
    product_id: '2873734',
    sku: 'YARD24',
    seller_shop_id: '30',
    order_count: 9,
    productRevenue: 277.1149777117385,
    averageProductSalesPrice: 30.790553079082056,
  },
  {
    id: '112059',
    title:
      'Josh Stein For North Carolina Governor Yard Sign, Harris Walz 2024 Yard Sign, 2024 Campaign Election Sign, Democrat Yard Sign,vote yard sign',
    images: [
      'https://i.etsystatic.com/25457903/r/il/cec21e/6281994852/il_fullxfull.6281994852_8lno.jpg',
    ],
    is_image_saved: true,
    product_id: '2848206',
    sku: 'YARD21',
    seller_shop_id: '30',
    order_count: 5,
    productRevenue: 141.32,
    averageProductSalesPrice: 28.264,
  },
  {
    id: '112062',
    title:
      'Trump Vance 2024 Yard Sign, Trump President Vance Vice President 2024, Donald Trump Yard Sign, Trump Vote Yard Sign, Double Sided',
    images: [
      'https://i.etsystatic.com/25457903/r/il/c5246e/6263755780/il_fullxfull.6263755780_16k5.jpg',
    ],
    is_image_saved: true,
    product_id: '2846444',
    sku: 'YARD41',
    seller_shop_id: '30',
    order_count: 6,
    productRevenue: 93.43,
    averageProductSalesPrice: 15.571666666666667,
  },
  {
    id: '112085',
    title:
      'Trump Vance Yard Sign, Trump President Vance, Vice President 2024, Trump Yard Sign, Trump Vote Yard Sign, Double Sided,Take America Back',
    images: [
      'https://i.etsystatic.com/25457903/r/il/e4162f/6266460344/il_fullxfull.6266460344_4bh2.jpg',
    ],
    is_image_saved: true,
    product_id: '2863002',
    sku: 'YARD17',
    seller_shop_id: '30',
    order_count: 13,
    productRevenue: 430.21999999999997,
    averageProductSalesPrice: 33.09384615384615,
  },
];
const MarketplaceMyProductsDemo = () => {
  return (
    <div>
      <DemoShopInfoCardWrapper />
      <DemoMyProductsTable data={products} />
      <DemoMyProductsCard data={products} />
      <CampDiscountWarn />
    </div>
  );
};

export default MarketplaceMyProductsDemo;
