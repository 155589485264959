import React, { useEffect, useRef, useState } from 'react';
import LoadingComponent from '../common/LoadingComponent';
import { Edit2, Link1 } from 'iconsax-react';
import OrderMatchingModal from '../marketplaceOrders/OrderMatchingModal';
import httpClient from '../../utils/httpClient';
import alertNotification from '../../utils/alertNotification';
import API_URL from '../../config';
import {
  ArrowUturnRightIcon,
  DocumentPlusIcon,
} from '@heroicons/react/20/solid';
import { Trash } from 'iconsax-react';
import marketplaceService from '../../services/marketplaceService';
import { classNames } from '../../utils/conditionalClasses';
import DesignModal from '../marketplaceOrders/DesignModal';

const tableHeader = [
  { name: 'Varyasyonlar' },
  { name: 'Özellik' },
  { name: 'Eşleşen Ürün' },
  { name: 'Tasarım' },
  { name: 'İşlem' },
];
interface Props {
  data: any;
  isLoading: boolean;
  refetch: () => void;
}

const ProductVariationsTable: React.FC<Props> = ({
  isLoading,
  data,
  refetch,
}) => {
  const [selectedOrder, setSelectedOrder] = React.useState<any>();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [localData, setLocalData] = React.useState<any[]>(
    data?.data?.sellerProductVariants
  );
  const [selectedProductId, setSelectedProductId] = React.useState<number>(0);
  const [isDesignModalOpen, setIsDesignModalOpen] = useState(false);

  // const handleCheckboxChange = async (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   sellerProductId: number
  // ) => {
  //   const isChecked = event.target.checked;
  //   console.log('isChecked', isChecked,sellerProductId,localData);
  //   // UI anında güncellensin
  //   let updatedLocalData = localData.map((prod:any) =>
  //     prod.id === sellerProductId ? { ...prod, isImageSaved: isChecked } : prod
  //   );
  //   console.log('updatedLocalData', updatedLocalData);
  //   setLocalData(updatedLocalData);

  //   console.log(localData)
  //   try {
  //     // Sunucuya isteği gönder
  //     await httpClient.patch('/api/v1/marketplace/seller/isImageSaved', {
  //       sellerProductId,
  //       isImageSaved: isChecked,
  //     });
  //   } catch (error) {
  //     console.error('Error updating isImageSaved:', error);

  //     // Hata durumunda UI'yı eski haline getir
  //     setLocalData((prevData) =>
  //       prevData.map((prod) =>
  //         +prod.id === sellerProductId
  //           ? { ...prod, isImageSaved: !isChecked }
  //           : prod
  //       )
  //     );
  //   }
  // };
  const handleProductMatch = (prod: any) => {
    setSelectedOrder({
      id: prod.id,

      seller_order_items: [
        {
          id: prod.seller_order_item_id,
          customizationData:Object.keys(prod.customization_data).length>0 ? prod.customization_data : ['',' '],
          seller_product: {
            id: prod.id,
            title: prod.title,
            images: prod.images,
            attributes: [prod.attributes[0]],
          },
          isRematch: prod.supplier_product_variant_id ? true : false,
        },
      ],
    });
    setIsModalOpen(true);
  };

  const deleteDesign = async (
    productId: string,
    designId: string,
    deleteFrom: string
  ) => {
    try {
      await marketplaceService.deleteDesign(
        '0',
        designId,
        deleteFrom,
        productId
      );
      refetch();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  useEffect(() => {
    setLocalData(data?.data?.sellerProductVariants);
  }, [data]);
  return (
    <div className="flow-root">
      <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
        <div className="inline-block min-w-full py-2 align-middle px-4">
          <div className=" flex flex-col flex-shrink-0 bg-[#F9FAFB] rounded-xl mt-8">
            <div className="relative overflow-y-auto overflow-x-auto scrollbar-hide">
              <div className="inline-block min-w-full align-middle h-[100vh]">
                <table className="w-full">
                  <thead>
                    <tr>
                      {tableHeader.map((item, index) => {
                        return (
                          <th
                            key={index}
                            className="sticky top-0 z-10 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                            // scope="col"
                          >
                            {' '}
                            <p className="text-xs md:text-sm">{item.name}</p>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>

                  {isLoading ? (
                    <div className="w-full flex justify-center">
                      <LoadingComponent />
                    </div>
                  ) : (
                    <tbody className="divide-y divide-gray-200 bg-[#F9FAFB]">
                      {/* <tr className="h-10"></tr> */}
                      {localData?.map((product: any, idx: number) => {
                        const localProduct =
                          localData &&
                          localData?.find((item) => item.id === product.id);
                        return (
                          <tr key={idx} className="text-[#374151]">
                            <td className="px-1 text-xs md:text-sm py-2">
                              <div className="flex justify-center items-center">
                                <img
                                  src={product.images[0]}
                                  alt="product_etsy"
                                  className="w-[80px] aspect-square rounded-xl"
                                />
                                <p className="pl-2 pr-8 w-[450px]">
                                  {product.title}
                                </p>
                              </div>
                            </td>
                            <td className="px-1 text-xs md:text-sm">
                              <div className="flex flex-col justify-center w-60">
                                {Object.entries(product.attributes[0]).map(
                                  ([key, value]) => {
                                    if(!key.includes('CustomizedURL'))
                                    return <div
                                      className="flex space-x-1 items-center"
                                      key={`${key}`}
                                    >
                                      <p className="text-[#1F2937] font-medium">
                                        {key}:
                                      </p>
                                      <p>{value as string}</p>
                                    </div>
                                  }
                                )}
                                {
                                  product.customization_data && product.customization_data.length > 0 && 
                                    <div>
                                      {
                                        product.customization_data.map((item:string,index:number)=>{
                                          if(index>8){

                                            return(
                                              <p>{item.replace( /- Ek Maliyet: 0\n?/g,
                                                                      '')}</p>
                                            )
                                          }
                                        })
                                      }
                                    </div>
                                                                  
                                                                
                                  
                                }
                              </div>
                            </td>

                            <td className="text-xs md:text-sm text-[#374151]">
                              <div className="flex justify-center items-center space-x-2">
                                {product.supplier_product_images &&
                                  product.supplier_product_images.length >
                                    0 && (
                                    <>
                                      <img
                                        src={`https://api.rexven.com/images/marketplace/supplier-images/${product.supplier_product_images[0]}`}
                                        alt="producta"
                                        className="w-12 h-12 rounded-lg"
                                      />
                                      <p className="w-64">
                                        {product.supplier_product_variant_name}
                                      </p>
                                    </>
                                  )}
                                {!product.supplier_product_variant_id && (
                                  <p className="text-sm">
                                    Bu ürün henüz eşleştirilmemiş
                                  </p>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="p-2 ">
                                <div className="mb-2 p-1 flex flex-col justify-center h-[200px] w-full items-center border-lightGray rounded-md">
                                  <div className="flex">
                                    {product.mockups
                                      ? product.mockups?.map(
                                          (mockup: any, index: number) => {
                                            return (
                                              <div>
                                                <img
                                                  onLoad={() => {}}
                                                  className="w-32 aspect-square object-contain object-center rounded-lg"
                                                  src={`${API_URL}${mockup.path}`}
                                                  alt="Mockup"
                                                />

                                                <button
                                                  className=" border-2 border-[#fb3c3c] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                  onClick={() => {
                                                    setSelectedProductId(
                                                      Number(product.id)
                                                    );
                                                    deleteDesign(
                                                      product.id,
                                                      mockup.id,
                                                      'sellerProduct'
                                                    );
                                                  }}
                                                >
                                                  <Trash
                                                    color="#fb3c3c"
                                                    size={18}
                                                  />
                                                  <p className="text-[#fb3c3c] text-xs">
                                                    Sil
                                                  </p>
                                                </button>
                                              </div>
                                            );
                                          }
                                        )
                                      : null}
                                  </div>
                                  <button
                                    disabled={
                                      product.designs &&
                                      product.designs.length > 2
                                    }
                                    className={classNames(
                                      'bg-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2',
                                      product.designs &&
                                        product.designs.length > 2
                                        ? 'cursor-not-allowed bg-gray-600 '
                                        : ''
                                    )}
                                    onClick={() => {
                                      setSelectedProductId(product.id);
                                      setIsDesignModalOpen(true);
                                    }}
                                  >
                                    <DocumentPlusIcon
                                      color="white"
                                      width={24}
                                    />
                                    <p className="text-white text-xs">Ekle</p>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td className="text-xs md:text-sm text-[#374151]">
                              <div className="flex justify-center items-center">
                                {!product.supplier_product_variant_id ? (
                                  <button
                                    className="bg-[#4F46E5] rounded-md space-x-2 px-4 py-1 flex justify-center items-center text-white"
                                    onClick={() => handleProductMatch(product)}
                                  >
                                    <Link1 size={20} color="#fff" />
                                    <p>Eşleştir</p>
                                  </button>
                                ) : (
                                  <button
                                    className="bg-white border-[#4F46E5] border rounded-md space-x-2 px-4 py-1 flex justify-center items-center text-[#4F46E5]"
                                    onClick={() => handleProductMatch(product)}
                                  >
                                    <Edit2 size={20} color="#4F46E5" />
                                    <p>Yeniden Eşleştir</p>
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderMatchingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedOrder={selectedOrder}
        getOrders={refetch}
      />
      {selectedProductId && (
        <DesignModal
          isCustom={false}
          open={isDesignModalOpen}
          isImageSaved={true}
          setOpen={setIsDesignModalOpen}
          selectedProductId={selectedProductId}
          selectedOrderItemId={0}
          getOrders={refetch}
          setIsModalOpen={setIsDesignModalOpen}
          isUpdate={false}
          addedFrom="productsPage"
        />
      )}
    </div>
  );
};

export default ProductVariationsTable;
