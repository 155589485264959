import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Pagination from '../components/common/Pagination';
// import CampDiscountWarn from '../components/discountModal/CampDiscountWarn';
import OrderFilterForm from '../components/marketplaceOrders/OrderFilterForm';
import OrderTable from '../components/marketplaceOrders/OrderTable';
import { useAuth } from '../context/AuthContext';
import marketplaceService from '../services/marketplaceService';
import memberService from '../services/memberService';
import alertNotification from '../utils/alertNotification';
import ErrorFallback from './errors/ErrorFallback';
import CampDiscountWarn from '../components/discountModal/CampDiscountWarn';

export interface EtsyOrder {
  id: number;
  serviceCode: string;
  etsyStoreId: number;
  userId: number;
  email: string;
  shippingMethod: string;
  quantityTotal: number;
  productPrice: number;
  shippingPrice: number;
  handlingPrice: number;
  taxPrice: number;
  discountPrice: number;
  totalPrice: number;
  currencyCode: string;
  orderId: number;
  orderDate: Date;
  customerFirstName: string;
  customerLastName: string;
  customerCompany: string;
  customerAddress1: string;
  customerAddress2: string;
  customerAddress3: string;
  customerAddress4: string;
  customerCity: string;
  customerState: string;
  customerPostalCode: string;
  customerCountry: string;
  customerPhone: string;
  giftMessage: string;
  isGift: string;
  needsGiftWrap: string;
  buyerMessage: string;
  shipmentCost: number;
  shipByDate: Date;
  allShipmentCost: any[];
  seller_order_items: EtsyOrderItem[];
}

export interface EtsyOrderItem {
  id: number;
  etsyOrderId: number;
  userId: number;
  name: string;
  itemPrice: number;
  quantity: number;
  weight: number;
  label: string;
  supplierOrderProductVariantPrice: number;
  productCode: string;
  variation_list: {
    [key: string]: string;
  };
  etsyListingId: string;
  itemImage: string;
  itemCost: number;
  status: string;
  marketplaceProductId: number;
  totalPrice: number;
}

export interface RootMarketplaceOrders {
  status: string;
  data: EtsyOrder[];
}

export const parseBoolean = (value: string | null): boolean => {
  return value === 'true' ? true : false;
};
const MarketplaceOrders = () => {
  const { session } = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  let temp = Object.fromEntries([...searchParams]);
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [inputPage, setInputPage] = useState<number | string>(
    Number(searchParams.get('page')) || 1
  );
  const [shops, setShops] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState(1);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const [queryString, setQueryString] = useState(
    `shop=${searchParams.get('shop') ? searchParams.get('shop') : ''}&country=${
      searchParams.get('country') ? searchParams.get('country') : ''
    }&startDate=${
      searchParams.get('startDate') === null
        ? ''
        : searchParams.get('startDate')
    }&endDate=${
      searchParams.get('endDate') === null ? '' : searchParams.get('endDate')
    }&isGift=${searchParams.get('isGift')}&isGiftWrap=${searchParams.get(
      'isGiftWrap'
    )}&customer=${
      searchParams.get('customer') ? searchParams.get('customer') : ''
    }&label=${searchParams.get('label')}&orderId=${searchParams.get('orderId')}`
  );
  const [queryParams, setQueryParams] = useState<any>({
    shop: temp?.shop || '',
    country: temp?.country || '',
    startDate: temp?.startDate
      ? new Date(temp?.startDate).toISOString().split('T')[0]
      : '',
    endDate: temp?.endDate
      ? new Date(temp?.endDate).toISOString().split('T')[0]
      : '',
    isGift: parseBoolean(temp?.isGift) || '',
    isGiftWrap: parseBoolean(temp?.isGiftWrap) || '',
    customer: temp?.customer || '',
    label: temp?.label || '',
    orderId: temp?.orderId || '',
  });

  const [balance, setBalance] = useState(0);
  const [labelCount, setLabelCount] = useState();
  const [cargoPrices, setCargoPrices] = useState<any[]>([]);

  const getSellerOrders = async () => {
    setIsLoading(true);
    try {
      const res = await marketplaceService.getEtsyOrders(page, queryParams);
      setShops(res.data.sellerShops);
      const groupedById = res.data.data.reduce((acc: any, current: any) => {
        const { id, seller_order_items } = current;

        if (!acc[id]) {
          acc[id] = { ...current, seller_order_items: [...seller_order_items] };
        } else {
          acc[id].seller_order_items.push(...seller_order_items);
        }

        return acc;
      }, {});

      setLabelCount(res.data.labelCounts);
      const temp = Object.values(groupedById).sort(
        (a: any, b: any) =>
          (new Date(b.orderDate) as any) - (new Date(a.orderDate) as any)
      ) as EtsyOrder[];
      setData(temp);
      const cargos = temp.map((order) => {
        return {
          orderId: order.id,
          shipmentCost: order.shipmentCost,
          allShipmentCost: order.allShipmentCost,
          orderItems: order.seller_order_items,
          serviceCode: order.serviceCode,
        };
      });
      setCargoPrices(cargos);
      setTotalPage(res.data.totalPageCount);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  // const secGetSellerOrders = async () => {
  //   const res = await marketplaceService.getEtsyOrders(page, queryParams);
  //   const groupedById = res.data.data.reduce((acc: any, current: any) => {
  //     const { id, seller_order_items } = current;

  //     if (!acc[id]) {
  //       acc[id] = { ...current, seller_order_items: [...seller_order_items] };
  //     } else {
  //       acc[id].seller_order_items.push(...seller_order_items);
  //     }

  //     return acc;
  //   }, {});
  //   if (
  //     (Object.values(groupedById) as EtsyOrder[]).sort(
  //       (a: EtsyOrder, b: EtsyOrder) =>
  //         (new Date(b.orderDate) as any) - (new Date(a.orderDate) as any)
  //     )[0].id !== data[0]?.id
  //   ) {
  //     setData(
  //       Object.values(groupedById).sort(
  //         (a: any, b: any) =>
  //           (new Date(b.orderDate) as any) - (new Date(a.orderDate) as any)
  //       ) as EtsyOrder[]
  //     );
  //     setTotalPage(res.data.totalPageCount);
  //     setShops(res.data.sellerShops);
  //   }
  // };

  // const handleStatusFilter = (status: string) => {
  //   setStatusFilter(status);
  // };
  const getWalletBalance = async () => {
    try {
      const res = await marketplaceService.getWalletBalance();
      setBalance(Number(res.data.balance));
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  useEffect(() => {
    getSellerOrders();

    // const timer = setTimeout(() => {
    //   getSellerOrders();}, 10000);

    // return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, queryParams]);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('marketplace-orders', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  useEffect(() => {
    if (!session?.isMarketplaceSeller) {
      navigate('/marketplace-orders-demo');
    }
    getWalletBalance();
  }, []);

  return (
    <div>
      {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
      <OrderFilterForm
        setQueryParams={setQueryParams}
        shops={shops}
        queryParams={queryParams}
        page={page}
        setInputPage={setInputPage}
        setQueryString={setQueryString}
        setPage={setPage}
      />

      <OrderTable
        page={page}
        queryParams={queryParams}
        cargoPrices={cargoPrices}
        setPage={setPage}
        setInputPage={setInputPage}
        labelCounts={labelCount}
        setCargoPrices={setCargoPrices}
        isLoading={isLoading}
        data={data}
        setQueryString={setQueryString}
        setQueryParams={setQueryParams}
        getWalletBalance={getWalletBalance}
        balance={balance}
        getOrders={getSellerOrders}
      />
      <Pagination
        page={Number(page)}
        setPage={setPage}
        totalPageCount={totalPage}
        inputPage={inputPage}
        setInputPage={setInputPage}
        queryString={queryString}
      />
      {/* </ErrorBoundary> */}
      {!session?.isMarketplaceSeller && <CampDiscountWarn />}
    </div>
  );
};

export default MarketplaceOrders;
