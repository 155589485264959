import React from 'react';
import { useAuth } from '../../context/AuthContext';
import marketplaceService from '../../services/marketplaceService';
import alertNotification from '../../utils/alertNotification';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowLeft, Copy } from 'iconsax-react';
import { useState } from 'react';
import { copyToClipboard } from '../../utils/copyToClipboard';

interface Props {
  depositAmount: number;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  setIsWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
  balance: number;
}
const EFTModal: React.FC<Props> = ({
  depositAmount,
  setIsModalOpen,
  isModalOpen,
  setIsWalletModal,
  balance,
}) => {
  const { session } = useAuth();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const sendDepositRequest = async () => {
    try {
      marketplaceService.createWalletDeposit({
        amount: depositAmount,
        note: '',
      });
      alertNotification('success', 'Para yükleme talebi başarılı');
      setIsModalOpen(false);
      setShowAlertModal(true);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  const handleCopyClick = () => {
    copyToClipboard(session?.id?.toString() || '');
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
  };

  return (
    <>
      <Transition show={isModalOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsModalOpen(false)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white py-8 px-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-start leading-6"
                  >
                    <div className="relative pt-6">
                      <p className="text-[24px] font-bold text-headerPrimary mb-2">
                        Cüzdan:{' '}
                      </p>
                      <p className="text-4xl font-bold text-[#059669]">
                        ${Number(balance).toFixed(2)}
                      </p>
                      <ArrowLeft
                        onClick={() => {
                          setIsModalOpen(false);
                          setIsWalletModal(true);
                        }}
                        color="#1D4ED8"
                        size={32}
                        className="absolute -top-4 left-0 cursor-pointer"
                      />
                    </div>
                    <div className="mt-4">
                      <div className="rounded-xl flex flex-col items-center bg-[#F9FAFB] text-[#374151] p-4 text-sm">
                        <div className="flex-1 border-b-2 py-3 w-full">
                          <p>Amerika Hesabı</p>
                          <p className="font-thin mb-3">
                            (Amerika'dan gönderim yapacaksanız bu hesaba gönderim
                            yapınız)
                          </p>
                          <p className="font-bold mb-1">
                            Hesap Sahibi:{' '}
                            <span className="font-thin ml-1">ANVENTURE LLC</span>{' '}
                          </p>
                          <p className="font-bold">
                            ACH ve havale yönlendirme numarası:
                            <span className="font-thin ml-1">026073150</span>
                          </p>
                          <p className="font-bold">
                            Hesap numarası:
                            <span className="font-thin ml-1">822000589258</span>
                          </p>
                          <p className="font-bold">
                            Hesap türü:
                            <span className="font-thin ml-1">Checking</span>
                          </p>
                          <p className="font-bold">
                            Wise'ın adresi:
                            <span className="font-thin ml-1">
                              30 W. 26th Street, Sixth Floor New York NY 10010
                              United States
                            </span>
                          </p>
                        </div>

                        <div className="flex-1 py-3 w-full">
                          <p>Diğer Hesap</p>
                          <p className="font-thin mb-3">
                            (Amerika dışındaki ülkelerden gönderim yapacaksanız bu
                            hesaba gönderim yapınız)
                          </p>
                          <p className="font-bold mb-1">
                            Hesap Sahibi:{' '}
                            <span className="font-thin ml-1">ANVENTURE LLC</span>{' '}
                          </p>
                          <p className="font-bold">
                            Yönlendirme numarası:
                            <span className="font-thin ml-1">026073150</span>
                          </p>
                          <p className="font-bold">
                            Swift/BIC:
                            <span className="font-thin ml-1">CMFGUS33</span>
                          </p>
                          <p className="font-bold">
                            Hesap numarası:
                            <span className="font-thin ml-1">822000589258</span>
                          </p>
                          <p className="font-bold">
                            Wise'ın adresi:
                            <span className="font-thin ml-1">
                              30 W. 26th Street, Sixth Floor New York NY 10010
                              United States
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="mt-8 text-[#374151]">
                        <p className="font-bold mb-3">
                          Yüklemek İstediğiniz Miktar
                        </p>
                        <p className="mb-3">
                          ${Number(depositAmount).toFixed(2)}
                        </p>

                        <p className="text-sm text-rexRed">ÖNEMLİ: </p>
                        <p className="text-sm font-thin">
                          Para gönderirken{' '}
                          <span className="text-[#2563EB] font-bold">
                            {session?.id}
                          </span>{' '}
                          olan müşteri numaranızı açıklama bölümüne yazınız
                        </p>
                        <p className="font-thin text-sm mb-4">
                          Anventure LLC, Rexven'in Amerika'daki şirketidir
                        </p>

                        <div className="flex justify-end">
                          <button
                            onClick={() => sendDepositRequest()}
                            className="bg-[#1D4ED8]  hover:bg-blue-700 text-white py-2 px-2 rounded-lg"
                          >
                            Para Yükleme Talebinde Bulun
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition show={showAlertModal} as={React.Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setShowAlertModal(false)}
        >
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900 flex items-center"
                  >
                    <span className="text-yellow-500 mr-3 text-2xl">⚠️</span>
                    <span className="flex-1">
                      Lütfen havale açıklamasına müşteri numaranızı (
                      <span
                        className={`ml-1 ${isCopied ? 'text-[#0D9488]' : 'text-rexRed'}`}
                      >
                        {session?.id}
                      </span>
                      <button
                        className="inline-block ml-2 cursor-pointer text-blue-500 mr-1"
                        onClick={handleCopyClick}
                      >
                        <Copy size={20} color="#1D4ED8" />
                      </button>
                      ) giriniz
                    </span>
                  </Dialog.Title>
                  <div className="mt-6 flex justify-end">
                    <button
                      type="button"
                      className="bg-[#1D4ED8] hover:bg-blue-700 text-white py-3 px-4 rounded-lg text-lg"
                      onClick={() => setShowAlertModal(false)}
                    >
                      Tamam
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EFTModal;
