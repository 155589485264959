import React from 'react';
import ShopInfoCardWrapper from '../components/marketplace/ShopInfoCardWrapper';
import MyProductFilter from '../components/marketplace/MyProductFilter';
import alertNotification from '../utils/alertNotification';
import marketplaceService from '../services/marketplaceService';
import { useQuery } from '@tanstack/react-query';
import MyProductsTable from '../components/marketplace/MyProductsTable';
import Pagination from '../components/common/Pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MobileProductCard from '../components/marketplaceSuppliersProducts/MobileProductCard';
import { useAuth } from '../context/AuthContext';
import CampDiscountWarn from '../components/discountModal/CampDiscountWarn';

export interface IMarketplaceProduct {
  id: string;
  title: string;
  images: string[];
  is_image_saved: boolean;
  product_id: string;
  sku: string;
  seller_shop_id: string;
  design_images: any[];
  mockup_images: any[];
  created_at: string;
  updated_at: string;
  order_count: string;
}

interface IRootProducts {
  status: string;
  data: {
    sellerProducts: IMarketplaceProduct[];
    totalPageCount: number;
  };
}
const MarketplaceMyProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { session } = useAuth();

  let temp = Object.fromEntries([...searchParams]);
  const [page, setPage] = React.useState(1);
  const [keyword, setKeyword] = React.useState(
    searchParams.get('keyword') || ''
  );
  const navigate = useNavigate();

  const [storeId, setStoreId] = React.useState(
    searchParams.get('storeId') || ''
  );
  const [sku, setSku] = React.useState(searchParams.get('sku') || '');
  const [inputPage, setInputPage] = React.useState<number | string>(1);
  const [queryString, setQueryString] = React.useState(
    `keyword=${keyword}&page=${page}&storeId=${storeId}&sku=${sku}`
  );

  const { isLoading, error, data } = useQuery<IRootProducts, Error>(
    ['myMarketplaceProducts', page, keyword, storeId, sku],
    {
      queryFn: () =>
        marketplaceService.getMyMarketplaceProducts(
          page,
          keyword,
          storeId,
          sku
        ),
      staleTime: Infinity,
    }
  );
  if (session?.isMarketplaceSeller === false) {
    navigate('/marketplace-my-products-demo');
  }
  if (error) {
    alertNotification('error', error.message);
  }

  return (
    <div>
      <ShopInfoCardWrapper />
      <MyProductFilter
        setKeyword={setKeyword}
        setSku={setSku}
        setShopId={setStoreId}
        setQueryString={setQueryString}
        setPage={setPage}
      />
      <MyProductsTable
        data={data?.data?.sellerProducts!}
        isLoading={isLoading}
      />
      <MobileProductCard
        data={data?.data?.sellerProducts!}
        isLoading={isLoading}
      />
      <Pagination
        page={Number(page)}
        setPage={setPage}
        totalPageCount={data?.data.totalPageCount!}
        inputPage={inputPage}
        setInputPage={setInputPage}
        queryString={queryString}
      />
      {!session?.isMarketplaceSeller && <CampDiscountWarn />}
    </div>
  );
};

export default MarketplaceMyProducts;
