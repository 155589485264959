import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import alertNotification from '../../utils/alertNotification';
import marketplaceService from '../../services/marketplaceService';
import { useAuth } from '../../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { handleRequest } from 'msw';
import { Link, useSearchParams } from 'react-router-dom';
import memberService from '../../services/memberService';
interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDesignModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productId: string;
}
const AddDesignInfoModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  setIsDesignModalOpen,
  productId
}) => {
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white py-8 px-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-start leading-6"
                >
                  <div className="border-b-2 border-lightGray">
                    <p className="text-[24px] font-bold text-headerPrimary mb-2">
                      Lütfen Dikkat Ediniz
                    </p>
                    <p className="text-4xl font-bold text-[#059669]"></p>
                  </div>
                </Dialog.Title>
                <p className="my-4 ml-1">
                  Siparişinizdeki tasarımlar ürününüze kaydedilmiştir. Bu
                  tasarımları 
                  {/* <Link
                    id="message"
                    target="_blank"
                    to={`/seller-product-details/${productId}`}
                    className="text-[#1D4ED8] font-semibold"
                  > */}
                    <b> {' '}Ürünlerim{' '}</b>
                  {/* </Link> */}
                  bölümünden değiştirebilirsiniz.
                </p>

                <p className="text-[#E3211E] text-sm mt-4">
                  {' '}
                  Eğer bu sipariş için kayıtlı tasarımlarınızdan farklı bir
                  tasarım eklemek isterseniz, aşağıdaki butona tıklayarak yeni
                  tasarımınızı ekleyebilirsiniz.
                </p>
                <div className="flex justify-between items-center space-x-2 mt-4">
                  <button
                    className="bg-[#1D4ED8] text-white rounded-lg font-medium px-4 py-1 w-full"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsDesignModalOpen(true);
                    }}
                  >
                    Ekle
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddDesignInfoModal;
