import httpClient from '../utils/httpClient';

class MarketplaceService {
  public async getEtsyOrders(page?: string | number, queryParams?: any) {
    const response = await httpClient.get(
      `/api/v1/marketplace/seller/orders?page=${page}&shop=${queryParams?.shop}&label=${queryParams?.label}&country=${queryParams?.country}&isGift=${queryParams?.isGift}&customer=${queryParams?.customer}&startDate=${queryParams?.startDate}&endDate=${queryParams?.endDate}&orderId=${queryParams?.orderId}`
    );

    return response.data;
  }
  public async getSupplierOrders(page: number, queryParams?: any) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/orders?page=${page}&shop=${queryParams?.shop}&label=${queryParams?.label}&country=${queryParams?.country}&isGift=${queryParams?.isGift}&customer=${queryParams?.customer}&startDate=${queryParams?.startDate}&endDate=${queryParams?.endDate}&orderId=${queryParams?.orderId}`
    );

    return response.data;
  }
  public async getAllSupplierOrders(queryParams?: any) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/orders?shop=${queryParams?.shop}&label=${queryParams?.label}&country=${queryParams?.country}&isGift=${queryParams?.isGift}&customer=${queryParams?.customer}&startDate=${queryParams?.startDate}&endDate=${queryParams?.endDate}`
    );

    return response.data;
  }

  //get producers
  public async getMarketplaceProducts(
    page: number | string,
    keyword?: string,
    categoryId?: number | null,
    sku?: string
  ) {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/products?keyword=${keyword}&page=${page}&categoryId=${
        categoryId ? categoryId : ''
      }&sku=${sku}`
    );
    return data;
  }

  public async getMarketplaceProduct(id: number) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/product/${id}`
    );
    return response.data;
  }

  public async addNoteToOrder(orderItemId: number, note: string) {
    const response = await httpClient.patch(`/api/v1/marketplace/seller/note`, {
      orderItemId,
      note,
    });
    return response.data;
  }
  public async getMarketplaceSupplierInventory(page: number | string) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/inventory?page=${page}`
    );
    return response.data;
  }
  public async updateOrderLabel(payload: {
    orderItemIds: any[];
    label: string;
  }) {
    const response = await httpClient.patch(
      `/api/v1/marketplace/seller/order-label`,

      payload
    );
    return response.data;
  }
  public async updateSupplierOrderLabel(payload: {
    orderItemIds: any[];
    label: string;
  }) {
    const response = await httpClient.patch(
      `/api/v1/marketplace/seller/order-label`,

      payload
    );
    return response.data;
  }
  public async updateSellerOrder(payload: {
    isGift: boolean;
    isGiftWrap: boolean;
    note: string;
    giftmessage: string;
    orderId: number;
    attributes?: any;
  }) {
    const response = await httpClient.patch(
      `/api/v1/marketplace/seller/order/${payload.orderId}`,

      payload
    );
    return response.data;
  }

  public async getWalletBalance() {
    const response = await httpClient.get(`/api/v1/marketplace/wallet/balance`);
    return response.data;
  }

  public async buySupplierProduct(orderIds: number[]) {
    const response = await httpClient.post(`/api/v1/marketplace/seller/buy`, {
      orderIds: orderIds,
    });
    return response.data;
  }

  public async cancelOrder(orderItemIds: number[]) {
    const response = await httpClient.post(
      `/api/v1/marketplace/seller/cancel-order`,
      {
        orderItemIds: orderItemIds,
      }
    );
    return response.data;
  }

  public async createWalletDeposit(payload: { amount: number; note: string }) {
    const response = await httpClient.post(
      `/api/v1/marketplace/wallet/deposit-request`,
      payload
    );
    return response.data;
  }
  public async resendOrderItems(payload: { orderIds: number[] }) {
    console.log(payload);
    const response = await httpClient.post(
      `/api/v1/marketplace/seller/send-again`,
      payload
    );
    return response.data;
  }
  public async resendPayment(payload: { orderIds: number[] }) {
    console.log(payload);
    const response = await httpClient.post(
      `/api/v1/marketplace/seller/send-again`,
      payload
    );
    return response.data;
  }
  public async getCargoPrice(payload: {
    country: string;
    carrier: string;
    weight: number;
    productId: number;
    productVariantId: number;
    productCount: number;
  }) {
    const response = await httpClient.get(
      `/api/v1/marketplace/cargo-predict?country=${payload.country}&carrier=${payload.carrier}&weight=${payload.weight}&productId=${payload.productId}&productVariantId=${payload.productVariantId}&productCount=${payload.productCount}`
    );
    return response.data;
  }
  public async getMarketplaceSellerProducts(
    page: number | string,
    keyword?: string,
    storeId?: number | null
  ) {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/seller/products-list?keyword=${keyword}&page=${page}&storeId=${
        storeId ? storeId : ''
      }`
    );
    return data;
  }

  public async getMarketplaceSellerShops() {
    const { data } = await httpClient.get(`/api/v1/marketplace/seller/shops`);
    return data;
  }
  public async getMarketplaceAllProducts(value: string) {
    const { data } = await httpClient.get(
      ` /api/v1/marketplace/all-products?keyword=${value}`
    );
    return data;
  }
  public async getShopStats() {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/seller/product-list-stats`
    );
    return data;
  }
  public async getMyShops() {
    const { data } = await httpClient.get(`/api/v1/marketplace/shops`);
    return data;
  }
  public async getMyMarketplaceProducts(
    page: number | string,
    keyword?: string,
    storeId?: number | string,
    sku: string = ''
  ) {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/seller/product-lists?page=${page}&keyword=${keyword}&storeId=${storeId}&sku=${sku}`
    );
    return data;
  }
  public async getMyProductDetails(
    productId: number,
    matching: string,
    attribute: string,
    storeId: number | string,
    page: number | string
  ) {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/seller/product-list/${productId}?storeId=${storeId}&matching=${matching}&attribute=${attribute}&page=${page}`
    );
    return data;
  }
  public async getMyProductDetailsStats(productId: number) {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/seller/product-list/${productId}/stats`
    );
    return data;
  }
  public async downloadLabels(paths: string[]) {
    const { data } = await httpClient.post(
      `/api/v1/marketplace/supplier/download-labels`,
      { paths }
    );
    return data;
  }
  public async resendRequest(payload: {
    orderItemId: number;
    description: string;
    subject: string;
    resendRequestImage: any;
  }) {
    const { data } = await httpClient.post(
      `/api/v1/marketplace/seller/order-send-again-requests`,
      payload
    );
    return data;
  }
  public async resendOrder(payload: { orderId: number }) {
    const { data } = await httpClient.post(
      `/api/v1/marketplace/seller/order-send-again`,
      payload
    );
    return data;
  }
  
  public async updateShopOrders() {
    const  {data}  = await httpClient.get(
      `/api/v1/marketplace/seller/update-shop-orders`
    );
    return data;
  }

  public async deleteDesign(orderItemId: string,designId: string,deleteFrom: string,productId: string) {
    const  {data}  = await httpClient.delete(
      `/api/v1/marketplace/seller/design/${orderItemId}?designId=${designId}&deleteFrom=${deleteFrom}&productId=${productId}`
    );
    return data;
  }
}

const marketplaceService = new MarketplaceService();
export default marketplaceService;
