import { Dialog, Transition } from '@headlessui/react';
import React from 'react';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  img: string;
}
const ImageDetailModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  img,
}) => {
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="w-full">
                  <img
                    src={img}
                    alt="resim"
                    className="w-full h-full aspect-square"
                  />
                </div>
                <div className="flex w-full justify-end">
                  <button
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                    className="bg-primary text-white px-4 py-2 rounded-md w-[180px] mt-2"
                  >
                    İptal
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ImageDetailModal;
