import React, { useEffect, useState } from 'react';
import LoadingComponent from '../common/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import { classNames } from '../../utils/conditionalClasses';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { EtsyOrder, EtsyOrderItem } from '../../pages/MarketplaceOrders';
import { formatDateTR } from '../../utils/date';
import OrderMatchingModal from './OrderMatchingModal';
import AddNoteModal from './AddNoteModal';
import { Link, useSearchParams } from 'react-router-dom';
import PaymentModal from './PaymentModal';
import { Tooltip } from 'react-tooltip';
import ReactCountryFlag from 'react-country-flag';
import {
  CloseCircle,
  Copy,
  DocumentDownload,
  Edit2,
  Trash,
  EmptyWallet,
  InfoCircle,
  Link2,
  Truck,
} from 'iconsax-react';
import StatusSection from './StatusSection';
import marketplaceService from '../../services/marketplaceService';
import alertNotification from '../../utils/alertNotification';
import WalletModal from './WalletModal';
import DocumentUploadModal from './DocumentUploadModal';
import UpdateOrderForm from './UpdateOrderForm';
import DesignModal from './DesignModal';
import ShipmentRadioGroup from './ShipmentRadioGroup';
import API_URL from '../../config';
import {
  ArrowUturnRightIcon,
  DocumentPlusIcon,
} from '@heroicons/react/20/solid';
import LoadingModal from '../common/LoadingModal';
import ResendModal from './ResendModal';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { ClipLoader } from 'react-spinners';
import EFTModal from '../marketplace/EFTModal';
import StripeModal from '../marketplace/StripeModal';
import CancelOrderModal from './CancelOrderModal';
import ResendOrderModal from './ResendOrderModal';
import httpClient from '../../utils/httpClient';
import RejectReasonModal from './RejectReasonModal';
import WarningText from './WarningText';
import AddDesignInfoModal from './AddDesignInfoModal';

interface MarketplaceOrderProps {
  page: number | string;
  isLoading: boolean;
  data: EtsyOrder[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  balance: number;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
  queryParams: any;
  setCargoPrices: React.Dispatch<any>;
  getWalletBalance: () => void;
  setQueryParams: React.Dispatch<any>;
  getOrders: () => void;
  labelCounts: any;
  cargoPrices: any[];
}

const MarketplaceOrderTable: React.FC<MarketplaceOrderProps> = ({
  page,
  cargoPrices,
  isLoading,
  data,
  setPage,
  setInputPage,
  balance,
  queryParams,
  setQueryParams,
  labelCounts,
  setCargoPrices,
  getOrders,
  setQueryString,
  getWalletBalance,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStripeModalOpen, setIsStripeModalOpen] = useState(false);
  const [isDesignModalOpen, setIsDesignModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedEtsyOrder, setSelectedEtsyOrder] = useState<any>(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [multiplier, setMultiplier] = useState(1);
  const [selectedProductId, setSelectedProductId] = useState<any>(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState<any[]>([]);
  const [selectedOrderItemId, setSelectedOrderItemId] = useState<any>(null);
  const [resendOrderItem, setResendOrderItem] = useState<any>(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [planId, setPlanId] = useState(600);
  const [resendModal, setResendModal] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [rejectReasonModal, setRejectReasonModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState(false);

  const [loadingLabel, setLoadingLabel] = useState(false);
  const [eftModalOpen, setEftModalOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState(250);
  const [isImageSaved, setIsImageSaved] = useState(false);
  const [resendOrder, setResendOrder] = useState<any>(null);
  // const [note, setNote] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [isUpdateOrdersDisabled, setIsUpdateOrdersDisabled] = useState(false);
  const [designAddInfoModal, setDesignAddInfoModal] = useState(false);

  const [productId, setProductId] = useState('');

  const [disabledOrders, setDisabledOrders] = useState<Record<number, boolean>>(
    {}
  );

  useEffect(() => {
    let refreshButtonDisabled = JSON.parse(
      localStorage.getItem('refreshButtonDisabled') || '{}'
    );
    const currentTime = new Date().getTime();

    if (currentTime < refreshButtonDisabled) {
      setIsUpdateOrdersDisabled(true);
      setTimeout(() => {
        setIsUpdateOrdersDisabled(false);
      }, refreshButtonDisabled - currentTime);
    }

    const savedDisabledOrders = JSON.parse(
      localStorage.getItem('resendButtonDisabledOrders') || '{}'
    );

    const now = new Date().getTime();
    const activeOrders: Record<number, boolean> = {};

    // Check local storage
    for (const [orderId, timestamp] of Object.entries(savedDisabledOrders)) {
      if (now < Number(timestamp)) {
        activeOrders[Number(orderId)] = true;
        setTimeout(() => {
          setDisabledOrders((prev) => ({
            ...prev,
            [Number(orderId)]: false,
          }));
          const updatedDisabledOrders = JSON.parse(
            localStorage.getItem('resendButtonDisabledOrders') || '{}'
          );
          delete updatedDisabledOrders[orderId];
          localStorage.setItem(
            'resendButtonDisabledOrders',
            JSON.stringify(updatedDisabledOrders)
          );
        }, Number(timestamp) - now);
      }
    }

    setDisabledOrders(activeOrders);
  }, []);

  const handleResendClick = (orderId: number, order: any) => {
    const disabledUntil = new Date().getTime() + 30 * 60 * 1000;

    // Disable button
    setDisabledOrders((prev) => ({
      ...prev,
      [orderId]: true,
    }));

    // LocalStorage save
    const savedDisabledOrders = JSON.parse(
      localStorage.getItem('resendButtonDisabledOrders') || '{}'
    );
    savedDisabledOrders[orderId] = disabledUntil;
    localStorage.setItem(
      'resendButtonDisabledOrders',
      JSON.stringify(savedDisabledOrders)
    );

    // activate button after 30 minutes if there is no refresh or page change
    setTimeout(() => {
      setDisabledOrders((prev) => ({
        ...prev,
        [orderId]: false,
      }));
      const updatedDisabledOrders = JSON.parse(
        localStorage.getItem('resendButtonDisabledOrders') || '{}'
      );
      delete updatedDisabledOrders[orderId];
      localStorage.setItem(
        'resendButtonDisabledOrders',
        JSON.stringify(updatedDisabledOrders)
      );
    }, 30 * 60 * 1000);

    // Resend
    const orderItems = order.seller_order_items.filter((item: any) => {
      return item.resendRequestStatus === 'approved';
    });
    setResendOrder({ orderId, orderItems });
    setIsResendModalOpen(true);
  };

  const { session } = useAuth();
  const { t } = useLanguage();
  const getShipstationLabel = async (orderId: number) => {
    setLoadingLabel(true);
    try {
      await httpClient.get(
        `/api/v1/marketplace/shipstation-label?platformOrderId=${orderId}`
      );
      alertNotification('success', 'Etiket alma işlemi başarılı');
      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoadingLabel(false);
    }
  };
  const isSameDay = (date1: Date, date2: Date) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };
  const getShipentegraLabel = async (orderId: number) => {
    setLoadingLabel(true);
    try {
      await httpClient.get(
        `/api/v1/marketplace/shipentegra-label?platformOrderId=${orderId}`
      );
      getOrders();
      alertNotification('success', 'Etiket alma işlemi başarılı');
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoadingLabel(false);
    }
  };
  const renderButton = (
    label: string,
    order: any,
    trackingNumber: number | null
  ) => {
    const isDisabled = disabledOrders[order.id] || false;
    if (
      label === 'waitingMatch' &&
      cargoPrices
        ?.find((item: any) => item.orderId === order.id)
        ?.orderItems.some(
          (item: any) => item.supplierOrderProductVariantPrice === null
        )
    ) {
      //burada cargoprices içinden order id ile eşleşen bir değer var mı kontrolü yapılmalı
      return (
        <button
          className={
            'text-white bg-[#4F46E5] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full xl:max-w-[200px] flex items-center justify-center space-x-2 cursor-pointer'
          }
          // disabled={selectedOrders.length === 0}
          onClick={() => {
            const tempOderItems = order.seller_order_items.map((item: any) => {
              return {
                ...item,
                isRematch: item.seller_product.supplierProductVariantId
                  ? true
                  : false,
              };
            });
            const filteredOrders = tempOderItems.filter((item: any) => {
              return item.seller_product.supplierProductVariantId === null;
            });
            const tempOrder = { ...order, seller_order_items: filteredOrders };

            setSelectedOrder(tempOrder);
            setIsModalOpen(true);
          }}
        >
          <Link2 color="white" size="24" variant="Bold" /> <p>{'Eşleştir'}</p>
        </button>
      );
    } else if (label === 'waitingPayment') {
      return (
        <button
          className={
            'text-white bg-[#0D9488] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full xl:max-w-[200px] flex justify-center items-center space-x-2 cursor-pointer'
          }
          onClick={() => {
            console.log(order)
            const designProducts:any[]=[]
            order.seller_order_items.map((item: any) => {
              if (item.supplierOrderNeedsDesign === true) {
                designProducts.push(item);
              }
            });
            console.log(designProducts)
            if (
              designProducts &&
              designProducts.length > 0 &&
              designProducts.some(
                (item: any) => item.designs && item.designs.length === 0
              )
            ) {
              alertNotification('error', 'Lütfen önce tasarım yükleyiniz');
            } else {
              setSelectedOrders([order]);
              setIsPaymentModalOpen(true);
            }
          }}
        >
          <EmptyWallet size="24" color="white" variant="Bold" />{' '}
          <p>Ödeme Yap</p>
        </button>
      );
      /* Cancel Button will be visible only if label is 'waitingProducing' and order trackingNumber is null */
    } else if (label === 'waitingProducing' && trackingNumber === null) {
      return (
        <>
          {Date.now() >=
            new Date(order.seller_etsy_orders.shipByDate).getTime() -
              86400000 && (
            <button
              className="text-white bg-[#F59E0B] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full xl:max-w-[200px] flex items-center justify-center space-x-2 cursor-pointer"
              disabled={order.labelPdf !== null}
              onClick={() => {
                if (
                  cargoPrices
                    .find((item) => item.orderId === order.id)
                    .allShipmentCost.some((item: any) =>
                      item.serviceCode.includes('shipentegra')
                    )
                ) {
                  getShipentegraLabel(order.platformOrderId);
                } else {
                  getShipstationLabel(order.platformOrderId);
                }
              }}
            >
              <DocumentDownload size="24" color="white" variant="Bold" />{' '}
              <p>Label Al</p>
            </button>
          )}
          <button
            className="text-white bg-[#EF4444] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full xl:max-w-[200px] flex justify-center items-center space-x-2 cursor-pointer"
            onClick={() => {
              setSelectedOrders([order]);
              setIsCancelModalOpen(true);
            }}
          >
            <CloseCircle size="24" color="white" variant="Bold" />{' '}
            <p>İptal Et</p>
          </button>
        </>
      );
    } else if (
      order.seller_order_items.some(
        (item: any) => item.resendRequestStatus === 'approved'
      )
    ) {
      return (
        <button
          className={`text-xs 2xl:text-sm px-4 py-2 rounded-full w-full xl:max-w-[200px] flex justify-center items-center space-x-2 ${
            isDisabled
              ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
              : 'bg-[#EF4444] text-white cursor-pointer'
          }`}
          onClick={() => handleResendClick(order.id, order)}
          disabled={isDisabled}
        >
          <ArrowUturnRightIcon width={24} height={24} color="white" />{' '}
          <p>Yeniden Gönder</p>
        </button>
      );
    } else if (label === 'waitingProducing' || label === 'producing') {
      const shipByDate = new Date(order.seller_etsy_orders.shipByDate);

      // Bir gün (24 saat) çıkarıyoruz.
      const oneDayBeforeShipByDate = new Date(shipByDate);
      oneDayBeforeShipByDate.setDate(shipByDate.getDate() - 1);
      if (
        order.labelPdf === null &&
        Date.now() >=
          new Date(order.seller_etsy_orders.shipByDate).getTime() - 86400000
      ) {
        return (
          <button
            className="text-white bg-[#F59E0B] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex items-center justify-center space-x-2 cursor-pointer"
            disabled={order.labelPdf !== null}
            onClick={() => {
              if (
                cargoPrices
                  .find((item) => item.orderId === order.id)
                  .allShipmentCost.some((item: any) =>
                    item.serviceCode.includes('shipentegra')
                  )
              ) {
                getShipentegraLabel(order.platformOrderId);
              } else {
                getShipstationLabel(order.platformOrderId);
              }
            }}
          >
            <DocumentDownload size="24" color="white" variant="Bold" />{' '}
            <p>Label Al</p>
          </button>
        );
      }
    }

    //  else if (
    //   label === 'delivered' ||
    //   label === 'shipped' ||
    //   label === 'inTransit'
    // ) {
    //   return (
    //     <button
    //       className="text-[#111827] border-2 border-[#111827] bg-transparent text-xs 2xl:text-sm px-4 py-2 rounded-full w-full"
    //       disabled={order.labelPdf !== null}
    //       onClick={() => {
    //         setResendModal(true);
    //         setResendOrderItem(order.seller_order_items);
    //       }}
    //     >
    //       Yeniden Gönderme Talebi
    //     </button>
    //   );
    // }
  };

  const tableHeader = [
    { name: 'Seç', column: 'choose' },
    { name: t?.('marketplace-orders.table.orderId'), column: 'orderId' },
    { name: 'Fotoğraf', column: 'product' },

    { name: 'Detay', column: 'details' },

    { name: t?.('marketplace-orders.table.price'), column: 'totalPrice' },
    { name: 'Kargo', column: 'shipment' },

    {
      name: 'Tasarım',
      column: 'design',
    },
    {
      name: 'İşlemler',
      column: 'edit',
    },
  ];

  const hideOrder = async (id: number[]) => {
    try {
      const label =
        searchParams.get('label') === 'hidden' ? 'hidden' : 'waitingMatch';
      console.log(id);
      if (label === 'hidden') {
        await marketplaceService.updateOrderLabel({
          orderItemIds: id,
          label: 'waitingMatch',
        });
      } else {
        await marketplaceService.updateOrderLabel({
          orderItemIds: id,
          label: 'hidden',
        });
      }
      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const calculateDate = (shipByDate: string) => {
    const shipDate = new Date(shipByDate);
    const today = new Date();

    if (
      today.getDate() === shipDate.getDate() &&
      today.getMonth() === shipDate.getMonth() &&
      today.getFullYear() === shipDate.getFullYear()
    ) {
      return 'today';
    } else if (today > shipDate) {
      return 'exceed';
    } else if (
      today.getDate() === shipDate.getDate() - 1 &&
      today.getMonth() === shipDate.getMonth() &&
      today.getFullYear() === shipDate.getFullYear()
    ) {
      return 'beforeOne';
    } else {
      return '';
    }
  };
  const toggleOrderSelection = (selectedItem: EtsyOrderItem) => {
    setSelectedOrders((prevSelectedOrders) => {
      const isAlreadySelected = prevSelectedOrders.some(
        (orderItem) => orderItem.id === selectedItem.id
      );

      if (isAlreadySelected) {
        return prevSelectedOrders.filter(
          (orderItem) => orderItem.id !== selectedItem.id
        );
      } else {
        return [...prevSelectedOrders, selectedItem];
      }
    });
  };

  const decideTracking = (serviceCode: string, trackingNumber: string) => {
    if (serviceCode.includes('shipentegra')) {
      return `https://track.shipentegra.com/track?showLastMileCarrier=true&trackno=${trackingNumber}`;
    } else if (serviceCode.includes('usps')) {
      return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}%2C&tABt=true`;
    } else if (serviceCode.includes('ups')) {
      return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
    }
  };

  const handleUpdateOrdersClick = async () => {
    try {
      let orderUpdate: any = await marketplaceService.updateShopOrders();
      console.log(orderUpdate);
      if (orderUpdate.message === 'Siparişler güncellendi') {
        setIsUpdateOrdersDisabled(true);
        setTimeout(() => {
          setIsUpdateOrdersDisabled(false);
        }, 30 * 60 * 1000);
        // save timestamp to local storage
        const now = new Date().getTime();
        const disabledUntil = now + 30 * 60 * 1000;
        localStorage.setItem(
          'refreshButtonDisabled',
          JSON.stringify(disabledUntil)
        );
        alertNotification(
          'success',
          'Siparişleri Güncelleme işlemi başlatıldı'
        );
      } else {
        alertNotification('error', 'Siparişler güncellenemedi');
      }
    } catch (error: any) {
      alertNotification('error', 'Siparişler güncellenemedi');
    }
  };

  const deleteDesign = async (
    orderItemId: string,
    designId: string,
    deleteFrom: string
  ) => {
    try {
      await marketplaceService.deleteDesign(
        orderItemId,
        designId,
        deleteFrom,
        '0'
      );
      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  useEffect(() => {
    if (session?.giftPackageName) {
      if (session?.giftPackageName === 'Rexven Premium') {
        setMultiplier(1.1);
      } else if (session?.giftPackageName === 'Rexven Plus') {
        setMultiplier(1.15);
      } else if (session?.giftPackageName === 'Elit') {
        setMultiplier(1.05);
      }
    } else {
      if (session?.packageName === 'Free') {
        setMultiplier(1.2);
      } else if (session?.packageName === 'Rexven Premium') {
        setMultiplier(1.1);
      } else if (session?.packageName === 'Rexven Plus') {
        setMultiplier(1.15);
      } else if (session?.packageName === 'Elit') {
        setMultiplier(1.05);
      }
    }
  }, [session]);

  return (
    <div className="flex flex-col flex-shrink-0 w-full bg-white rounded-2xl p-5 shadow-lg mt-2 sm:p-4 relative">
      <div className="flex justify-between items-center flex-1 space-x-4 p-4">
        <button
          className={classNames(
            selectedOrders.length === 0
              ? 'text-[#4B5563] bg-[#D1D5DB]'
              : 'text-white bg-[#1D4ED8]',
            ' text-xs 2xl:text-sm px-4 py-2 rounded-lg '
          )}
          disabled={selectedOrders.length === 0}
          onClick={() => {
            const designProducts = selectedOrders
              .flatMap((order: any) => order.seller_order_items)
              .map((item: any) => {
                if (item.supplierOrderNeedsDesign === true) {
                  return item;
                }
              });

            if (
              designProducts.length > 0 &&
              designProducts.some((item: any) => item.designs.length === 0)
            ) {
              alertNotification('error', 'Lütfen önce tasarım yükleyiniz');
            } else {
              setIsPaymentModalOpen(true);
            }
          }}
        >
          Ödeme Yap
        </button>

        <div>
          <button
            className={classNames(
              isUpdateOrdersDisabled
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#111827]',
              'text-white mx-3 font-medium text-xs 2xl:text-sm py-2 rounded-full w-44 border-none'
            )}
            onClick={handleUpdateOrdersClick}
            disabled={isUpdateOrdersDisabled}
          >
            {isUpdateOrdersDisabled
              ? 'Siparişler Güncelleniyor'
              : 'Siparişleri Yenile'}
          </button>
          <button
            className="bg-[#047857] text-white font-medium text-xs 2xl:text-sm py-2 rounded-full w-44 border-none"
            onClick={() => {
              setSearchParams('plan=600');
              setIsWalletModalOpen(true);
            }}
          >
            Cüzdan: ${Number(balance).toFixed(2)}
          </button>
        </div>
      </div>

      <StatusSection
        labelCounts={labelCounts}
        setQueryParams={setQueryParams}
        setInputPage={setInputPage}
        setQueryString={setQueryString}
        setPage={setPage}
      />

      {/* TABLE */}
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <div className=" flex flex-col flex-shrink-0 bg-[#F9FAFB] rounded-xl mt-8">
              <div className="relative overflow-y-auto overflow-x-auto">
                <div className="inline-block min-w-full align-middle h-[100vh]">
                  <table className="min-w-[1280px] w-full">
                    <thead>
                      <tr>
                        {tableHeader.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className="sticky top-0 z-[2] text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                              // scope="col"
                            >
                              {' '}
                              <p className="text-xs md:text-sm">{item.name}</p>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    {isLoading ? (
                      <div className="w-full flex justify-center">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-[#F9FAFB]">
                        <tr className="h-10"></tr>
                        {data?.map((order: any, idx) => {
                          return (
                            <tr
                              key={idx}
                              className={classNames(
                                idx === 0 ? 'border-none' : 'border-b-lightGray'
                              )}
                            >
                              <td className="px-1 text-xs md:text-sm sm:pl-0 ">
                                <div className="flex justify-center">
                                  {order?.seller_order_items[0]?.label ===
                                    'waitingPayment' && (
                                    <input
                                      type="checkbox"
                                      className="rounded-sm border-blue-500"
                                      checked={selectedOrders.some(
                                        (orderItem) => orderItem.id === order.id
                                      )}
                                      onChange={() =>
                                        toggleOrderSelection(order)
                                      }
                                    />
                                  )}
                                </div>
                              </td>
                              {/* <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                                <p className="text-center text-gray">
                                  {idx + 20 * (Number(page) - 1) + 1}
                                </p>
                              </td> */}
                              <td className="py-4 text-xs sm:pl-0 whitespace-nowrap text-gray-500">
                                <div className="flex flex-col items-start space-y-1">
                                  {order?.seller_order_items.some(
                                    (item: any) =>
                                      (item.lineItemKey &&
                                        item.lineItemKey.includes('faulty')) ||
                                      (item.lineItemKey &&
                                        item.lineItemKey.includes('other'))
                                  ) && (
                                    <WarningText warning="Yeniden Gönderim" />
                                  )}
                                  {(order?.seller_order_items[0]?.label ===
                                    'waitingMatch' ||
                                    order?.seller_order_items[0]?.label ===
                                      'waitingPayment' ||
                                    order?.seller_order_items[0]?.label ===
                                      'waitingProducing' ||
                                    order?.seller_order_items[0]?.label ===
                                      'producing') && (
                                    <WarningText
                                      warning={calculateDate(
                                        order.seller_etsy_orders.shipByDate
                                      )}
                                    />
                                  )}
                                  <p>
                                    <span className="text-headerPrimary">
                                      Tarih:
                                    </span>{' '}
                                    {formatDateTR(order.orderDate)}
                                  </p>
                                  <p>
                                    <span className="text-headerPrimary">
                                      Son tarih:{' '}
                                    </span>
                                    {order.seller_etsy_orders?.shipByDate &&
                                      formatDateTR(
                                        order.seller_etsy_orders.shipByDate
                                      )}{' '}
                                  </p>

                                  <p>
                                    <span className="text-headerPrimary">
                                      Sipariş no:{' '}
                                    </span>
                                    {order?.platformOrderId}
                                  </p>
                                  <p>
                                    <span className="text-headerPrimary">
                                      Müşteri:{' '}
                                    </span>
                                    {order?.fullName}
                                  </p>
                                  <div className="flex items-center space-x-1">
                                    <p className="text-headerPrimary">Ülke: </p>
                                    <p>
                                      {
                                        order?.seller_etsy_orders
                                          .customerCountry
                                      }
                                    </p>
                                    <Tooltip
                                      anchorSelect={`#address${idx}`}
                                      content={
                                        order?.seller_etsy_orders
                                          ?.customerAddress1
                                      }
                                      style={{
                                        width: '200px',
                                      }}
                                    />
                                    <ReactCountryFlag
                                      id={`address${idx}`}
                                      className="ml-1 text-base"
                                      svg
                                      countryCode={
                                        order?.seller_etsy_orders
                                          .customerCountry
                                      }
                                    />
                                  </div>

                                  {renderButton(
                                    order?.seller_order_items[0]?.label,
                                    order,
                                    order.trackingNumber
                                  )}
                                </div>
                              </td>
                              <td className="px-2">
                                <div
                                  className={classNames(
                                    order?.seller_order_items.length > 1
                                      ? 'space-y-2'
                                      : 'space-y-0',
                                    'px-2 py-2 flex flex-col items-center justify-center'
                                  )}
                                >
                                  {order.seller_order_items.map((item: any) => {
                                    return (
                                      <div>
                                        {item.seller_product.title ===
                                          'Gift Wrap' ||
                                        item.seller_product.title === '' ? (
                                          <img
                                            className="h-[160px] w-[160px] aspect-square object-cover object-center rounded-lg"
                                            src={'/images/giftwrap1.webp'}
                                            alt="Product"
                                          />
                                        ) : (
                                          <div>
                                            <img
                                              className=" h-[160px] w-[160px] aspect-square object-cover object-center rounded-lg mb-2"
                                              src={
                                                item.seller_product.images[0]
                                                  ? item.seller_product
                                                      .images[0]
                                                  : '/images/image-not-found.png'
                                              }
                                              alt="Product"
                                            />
                                          </div>
                                        )}

                                        {item.seller_product.title !==
                                          'Gift Wrap' &&
                                          item.seller_product.title !== '' && (
                                            <button
                                              className="py-1 px-4 bg-white text-[#1D4ED8] border border-[#1D4ED8] rounded-lg flex items-center justify-center w-[160px]"
                                              onClick={() => {
                                                const etsyOrder =
                                                  order?.seller_etsy_orders;
                                                let temp = {
                                                  id: item.id,
                                                  giftMessage:
                                                    etsyOrder.giftMessage,
                                                  buyerMessage:
                                                    etsyOrder.buyerMessage,
                                                  customizationData:
                                                    item.customizationData,
                                                  isGift: etsyOrder.isGift,
                                                  attributes:
                                                    item.seller_product
                                                      ?.attributes,
                                                  personalization:
                                                    item.personalization,
                                                  needsDesign:
                                                    item.supplierOrderNeedsDesign,
                                                  designFiles: item.designFiles,
                                                };

                                                setSelectedEtsyOrder(temp);
                                                setIsUpdateModalOpen(true);
                                              }}
                                            >
                                              <Edit2
                                                color="#1D4ED8"
                                                size={18}
                                              />
                                              <p className="text-xs">
                                                Güncelle
                                              </p>
                                            </button>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>

                              <td className="py-4 text-xs md:text-sm text-left overflow-hidden text-gray-500 sm:pl-0">
                                <div className="flex flex-col items-start space-y-4">
                                  {order.seller_order_items.map(
                                    (item: any, index: any) => (
                                      <div
                                        key={index}
                                        className="flex items-center space-x-4 px-2"
                                      >
                                        {/* "text-xs text-left text-[#1F2937] overflow-hidden h-16 font-medium" */}
                                        <div className="flex flex-col space-y-1 h-[200px] py-4 w-48 justify-center">
                                          <p
                                            className={classNames(
                                              item.seller_product.title ===
                                                'Gift Wrap'
                                                ? 'ml-12'
                                                : 'ml-0',
                                              'text-xs text-left text-[#1F2937] font-medium'
                                            )}
                                          >
                                            {item.seller_product.title.substring(
                                              0,
                                              44
                                            )}
                                            ...
                                          </p>

                                          {item.seller_product.title !==
                                            'Gift Wrap' && (
                                            <p className="text-xs text-[#1F2937]">
                                              Adet:{' '}
                                              <span className="text-gray-500">
                                                {item.quantity}
                                              </span>
                                            </p>
                                          )}

                                          {item.seller_product.title !==
                                            'Gift Wrap' && (
                                            <div className="text-xs text-left text-gray-500 min-w-[160px] h-[200px] overflow-y-auto">
                                              {item.seller_product.attributes
                                                .length > 0 &&
                                                Object.entries(
                                                  item.seller_product
                                                    .attributes[0]
                                                ).map(([key, value]) => {
                                                  if (
                                                    !key.includes(
                                                      'CustomizedURL'
                                                    )
                                                  ) {
                                                    return (
                                                      <div
                                                        className="flex space-x-1"
                                                        key={`${index}-${key}`}
                                                      >
                                                        <p className="text-[#1F2937]">
                                                          {key}:
                                                        </p>
                                                        <p>{value as string}</p>
                                                      </div>
                                                    );
                                                  } else {
                                                    return null;
                                                  }
                                                })}
                                              {(item.personalization ||
                                                item.customizationData.length >
                                                  0) && (
                                                <p className="font-medium text-[#1F2937]">
                                                  Kişiselleştirme:{' '}
                                                  <span className="font-normal">
                                                    {item.personalization
                                                      ? item.personalization
                                                      : item.customizationData
                                                      ? item.customizationData.map(
                                                          (
                                                            item: any,
                                                            index: number
                                                          ) => {
                                                            if (index > 8)
                                                              return (
                                                                <p>
                                                                  {item.replace(
                                                                    /- Ek Maliyet: 0\n?/g,
                                                                    ''
                                                                  )}
                                                                </p>
                                                              );
                                                          }
                                                        )
                                                      : '-'}
                                                  </span>
                                                </p>
                                              )}
                                            </div>
                                          )}
                                          <p className="font-medium text-[#1F2937] text-xs flex">
                                            Ürün hediye mi?:
                                            <span className="font-normal ml-1">
                                              {order.seller_etsy_orders
                                                .isGift === true
                                                ? 'Evet'
                                                : 'Hayır'}{' '}
                                            </span>
                                            <span
                                              className="ml-1"
                                              id={`isGift${idx}`}
                                            >
                                              <InfoCircle
                                                size={16}
                                                color="#111827"
                                              />
                                            </span>
                                          </p>

                                          {(item.label === 'shipped' ||
                                            item.label === 'inTransit' ||
                                            item.label === 'delivered') &&
                                            item.resendRequestStatus ===
                                              'rejected' &&
                                            item.rejectReason && (
                                              <div>
                                                <button
                                                  className="text-rexRed border-2 border-rexRed bg-transparent focus:outline-none focus:ring-0 text-xs px-2 py-1 rounded-full w-full"
                                                  // disabled={
                                                  //   order.labelPdf === null
                                                  // }
                                                  onClick={() => {
                                                    setSelectedReason(
                                                      item.rejectReason
                                                    );
                                                    setRejectReasonModal(true);
                                                  }}
                                                >
                                                  Red Sebebi
                                                </button>
                                              </div>
                                            )}
                                          {(item.label === 'shipped' ||
                                            item.label === 'inTransit' ||
                                            item.label === 'delivered') &&
                                            item.resendRequestStatus !==
                                              'pending' &&
                                            item.resendRequestStatus !==
                                              'approved' && (
                                              <div>
                                                <button
                                                  className="text-[#111827] border-2 border-[#111827] bg-transparent text-xs px-2 py-1 rounded-full w-full"
                                                  // disabled={
                                                  //   order.labelPdf === null
                                                  // }
                                                  onClick={() => {
                                                    setResendModal(true);
                                                    setResendOrderItem(item);
                                                  }}
                                                >
                                                  Yeniden Gönderme Talebi
                                                </button>
                                              </div>
                                            )}
                                          {(item.label === 'shipped' ||
                                            item.label === 'inTransit' ||
                                            item.label === 'delivered') &&
                                            item.resendRequestStatus ===
                                              'pending' && (
                                              <div>
                                                <button
                                                  className="text-[#111827] border-2 border-[#111827] bg-transparent text-xs px-2 py-1 rounded-full w-full"
                                                  disabled={true}
                                                >
                                                  Talep İncelemede
                                                </button>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <Tooltip
                                    anchorSelect={`#isGift${idx}`}
                                    content={
                                      `Mesaj:${
                                        order?.seller_etsy_orders.giftMessage
                                          ? order?.seller_etsy_orders
                                              .giftMessage
                                          : '-'
                                      }` +
                                      '/ ' +
                                      `Alıcı Mesajı:${
                                        order?.seller_etsy_orders.buyerMessage
                                          ? order?.seller_etsy_orders
                                              .buyerMessage
                                          : '-'
                                      }`
                                    }
                                  />
                                </div>
                              </td>

                              {/* <td className="py-4 text-xs text-left text-gray-500">
                                <div className="flex flex-col w-32 items-start space-y-1">
                                  <p>
                                    <span className="text-[#1F2937]">
                                      İsim:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerFirstName}{' '}
                                    {order.seller_etsy_orders.customerLastName}
                                  </p>
                                  {/* <div>
                                    <span className="text-[#1F2937]">
                                      Email:
                                    </span>{' '}
                                    {order.seller_etsy_orders.email}{' '}
                                  </div> */}
                              {/* <div>
                                    <span className="text-[#1F2937]">
                                      Adres:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerAddress1}
                                    {order.seller_etsy_orders.customerAddress2}
                                  </div>
                                  <p>
                                    <span className="text-[#1F2937]">
                                      Şehir:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerCity}
                                  </p>
                                  <p>
                                    <span className="text-[#1F2937]">
                                      Eyalet:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerState}{' '}
                                  </p>
                                  <p>
                                    <span className="text-[#1F2937]">
                                      Posta Kodu:{' '}
                                    </span>
                                    {
                                      order.seller_etsy_orders
                                        .customerPostalCode
                                    }
                                  </p>
                                  <div>
                                    <span className="text-[#1F2937]">
                                      Ülke:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerCountry}
                                  </div>
                                </div> */}
                              {/* </td> */}
                              <td className="py-4 text-center text-xs md:text-xs text-gray-500">
                                <div className="flex flex-col items-center space-y-1 w-28">
                                  <div>
                                    <span className="text-lg text-center font-medium text-headerPrimary">
                                      {order.currency === 'USD'
                                        ? '$'
                                        : order.currency}{' '}
                                      {Number(order.total).toFixed(2)}
                                    </span>
                                  </div>

                                  {order?.seller_order_items[0]
                                    ?.supplierOrderProductVariantPrice && (
                                    <div className="w-28">
                                      <span className="text-[#1F2937]">
                                        Alış:
                                      </span>
                                      $
                                      {order?.seller_order_items[0]
                                        ?.supplierOrderProductVariantPrice
                                        ? order?.seller_order_items
                                            .reduce(
                                              (acc: number, item: any) =>
                                                acc +
                                                item.quantity *
                                                  item.supplierOrderProductVariantPrice *
                                                  multiplier,
                                              0
                                            )
                                            .toFixed(2)
                                        : ' -'}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className="py-4 text-xs md:text-sm text-gray-500 sm:pl-0">
                                <div className="flex flex-col justify-center items-center w-full">
                                  {cargoPrices &&
                                  cargoPrices?.find((item: any) => {
                                    return (
                                      Number(item.orderId) === Number(order.id)
                                    );
                                  })?.allShipmentCost.length > 0 ? (
                                    <ShipmentRadioGroup
                                      groupIndex={idx}
                                      label={
                                        order?.seller_order_items[0]?.label
                                      }
                                      getOrders={getOrders}
                                      sellerOrderId={order?.id}
                                      allShipmentCost={
                                        cargoPrices?.find(
                                          (item: any) =>
                                            item.orderId === order.id
                                        )?.allShipmentCost
                                      }
                                      serviceCode={
                                        cargoPrices?.find(
                                          (item: any) =>
                                            item.orderId === order.id
                                        )?.serviceCode
                                      }
                                      country={
                                        order?.seller_etsy_orders
                                          .customerCountry
                                      }
                                    />
                                  ) : (
                                    <div className="w-full px-2 py-4 rounded-xl flex space-x-2 justify-center items-center bg-[#FFFBEB]">
                                      {order?.seller_order_items.some(
                                        (item: any) =>
                                          item.supplierOrderProductVariantPrice ===
                                          null
                                      ) && (
                                        <InfoCircle size={24} color="#F59E0B" />
                                      )}
                                      <p className="text-[#F59E0B] text-xs">
                                        {order?.seller_order_items.some(
                                          (item: any) =>
                                            item.supplierOrderProductVariantPrice ===
                                            null
                                        ) ? (
                                          'Lütfen tüm ürünleri eşleştiriniz'
                                        ) : (
                                          <ClipLoader color="#F59E0B" />
                                        )}
                                      </p>
                                    </div>
                                  )}
                                  {order.trackingNumber && (
                                    <div className="rounded-lg px-2 w-44">
                                      <button
                                        className="text-xs mt-2 border-gray-500 border-2 rounded-lg px-2 py-1 text-center mb-2 flex cursor-pointer focus:bg-gray-500 focus:text-white"
                                        onClick={() =>
                                          copyToClipboard(order?.trackingNumber)
                                        }
                                      >
                                        {order?.trackingNumber.substring(0, 16)}
                                        ...
                                        <Copy
                                          size={18}
                                          color="#374151"
                                          className="ml-1"
                                        />
                                      </button>
                                      <a
                                        className="flex space-x-2 cursor-pointer border-2 border-[#EA580C] rounded-lg px-2 py-1 text-white mx-auto items-center"
                                        target="_blank"
                                        rel="noreferrer"
                                        href={decideTracking(
                                          order?.serviceCode,
                                          order?.trackingNumber
                                        )}
                                      >
                                        <Truck size={24} color="#EA580C" />
                                        <p className="text-[#EA580C] text-xs">
                                          Kargo Takip
                                        </p>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="w-64 p-2">
                                  {order?.seller_order_items?.map(
                                    (item: any) => {
                                      return (
                                        <div className="mb-2 p-1 flex flex-col justify-center h-[200px] w-full items-center border-lightGray rounded-md">
                                          <div className="flex">
                                            {item?.mockups
                                              ? item.mockups?.map(
                                                  (
                                                    mockup: any,
                                                    index: number
                                                  ) => {
                                                    return (
                                                      <div>
                                                        <img
                                                          onLoad={() => {}}
                                                          className="w-32 aspect-square object-contain object-center rounded-lg"
                                                          src={`${API_URL}${mockup.path}`}
                                                          alt="Mockup"
                                                        />
                                                        {(item?.label ===
                                                          'waitingPayment' ||
                                                          item?.label ===
                                                            'waitingMatch') && (
                                                          <button
                                                            className=" border-2 border-[#fb3c3c] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                            onClick={() => {
                                                              if (
                                                                item.isOrderDesigns
                                                              ) {
                                                                setSelectedOrderItemId(
                                                                  Number(
                                                                    item.id
                                                                  )
                                                                );
                                                                deleteDesign(
                                                                  item.id,
                                                                  mockup.id,
                                                                  'orderItem'
                                                                );
                                                              } else {
                                                                setSelectedProductId(
                                                                  item
                                                                    .seller_product
                                                                    .id
                                                                );
                                                                setSelectedOrderItemId(
                                                                  Number(
                                                                    item.id
                                                                  )
                                                                );

                                                                setIsUpdate(
                                                                  false
                                                                );
                                                                setIsImageSaved(
                                                                  item
                                                                    .seller_product
                                                                    ?.sellerProductImagesSaved
                                                                );
                                                                setIsCustom(
                                                                  false
                                                                );
                                                                setProductId(
                                                                  item
                                                                    .seller_product
                                                                    .productId
                                                                );
                                                                setDesignAddInfoModal(
                                                                  true
                                                                );
                                                              }
                                                            }}
                                                          >
                                                            <Trash
                                                              color="#fb3c3c"
                                                              size={18}
                                                            />
                                                            <p className="text-[#fb3c3c] text-xs">
                                                              Sil
                                                            </p>
                                                          </button>
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )
                                              : null}
                                          </div>
                                          {((order?.seller_order_items[0]
                                            .label === 'waitingPayment' ||
                                            order?.seller_order_items[0]
                                              .label === 'waitingMatch') && item.supplierOrderNeedsDesign) && (
                                            <button
                                              disabled={item.designs.length > 2}
                                              className={classNames(
                                                'bg-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2',
                                                item.designs.length > 2
                                                  ? 'cursor-not-allowed bg-gray-600 '
                                                  : ''
                                              )}
                                              onClick={() => {
                                                setSelectedProductId(
                                                  item.seller_product.id
                                                );
                                                setSelectedOrderItemId(
                                                  Number(item.id)
                                                );
                                                setIsDesignModalOpen(true);
                                                setIsUpdate(false);
                                                setIsImageSaved(
                                                  item.seller_product
                                                    ?.sellerProductImagesSaved
                                                );
                                                setIsCustom(false);
                                              }}
                                            >
                                              <DocumentPlusIcon
                                                color="white"
                                                width={24}
                                              />
                                              <p className="text-white text-xs">
                                                Ekle
                                              </p>
                                            </button>
                                          )}

                                          {/* {item.supplierOrderNeedsDesign && (
                                            <div className="flex justify-center w-full space-x-2">
                                              {item.seller_product
                                                .sellerProductImagesSaved &&
                                                item.seller_product
                                                  ?.mockupImages &&
                                                item.seller_product
                                                  ?.mockupImages?.length > 0 &&
                                                order?.labelPdf === null && (
                                                  <button
                                                    className=" border-2 border-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                    onClick={() => {
                                                      setSelectedProductId(
                                                        item.seller_product.id
                                                      );
                                                      setSelectedOrderItemId(
                                                        Number(item.id)
                                                      );
                                                      setIsDesignModalOpen(
                                                        true
                                                      );
                                                      setIsUpdate(true);
                                                      setIsImageSaved(
                                                        item.seller_product
                                                          ?.sellerProductImagesSaved
                                                      );
                                                      setIsCustom(
                                                        item?.customizationData ||
                                                          item?.personalization
                                                          ? true
                                                          : false
                                                      );
                                                    }}
                                                  >
                                                    <Edit2
                                                      color="#FB923C"
                                                      size={18}
                                                    />
                                                    <p className="text-[#FB923C] text-xs">
                                                      Düzenle
                                                    </p>
                                                  </button>
                                                )}
                                              {!item.seller_product
                                                .sellerProductImagesSaved &&
                                                item.mockupFiles &&
                                                item.mockupFiles.length > 0 &&
                                                order?.labelPdf === null && (
                                                  <button
                                                    className=" border-2 border-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                    onClick={() => {
                                                      setSelectedProductId(
                                                        item.seller_product.id
                                                      );
                                                      setSelectedOrderItemId(
                                                        Number(item.id)
                                                      );
                                                      setIsDesignModalOpen(
                                                        true
                                                      );
                                                      setIsUpdate(true);
                                                      setIsImageSaved(
                                                        item.seller_product
                                                          ?.sellerProductImagesSaved
                                                      );
                                                      setIsCustom(
                                                        item?.customizationData ||
                                                          item?.personalization
                                                          ? true
                                                          : false
                                                      );
                                                    }}
                                                  >
                                                    <Edit2
                                                      color="#FB923C"
                                                      size={18}
                                                    />
                                                    <p className="text-[#FB923C] text-xs">
                                                      Düzenle
                                                    </p>
                                                  </button>
                                                )}
                                              {item.seller_product
                                                .sellerProductImagesSaved &&
                                                (item.seller_product
                                                  ?.mockupImages?.length < 3 ||
                                                  item.seller_product
                                                    ?.mockupImages === null) &&
                                                order?.labelPdf === null &&
                                                item?.label ===
                                                  'waitingPayment' && (
                                                  <button
                                                    className="bg-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                    onClick={() => {
                                                      setSelectedProductId(
                                                        item.seller_product.id
                                                      );
                                                      setSelectedOrderItemId(
                                                        Number(item.id)
                                                      );
                                                      setIsDesignModalOpen(
                                                        true
                                                      );
                                                      setIsUpdate(false);
                                                      setIsImageSaved(
                                                        item.seller_product
                                                          ?.sellerProductImagesSaved
                                                      );
                                                      setIsCustom(false);
                                                    }}
                                                  >
                                                    <DocumentPlusIcon
                                                      color="white"
                                                      width={24}
                                                    />
                                                    <p className="text-white text-xs">
                                                      Ekle
                                                    </p>
                                                  </button>
                                                )}
                                              {!item.seller_product
                                                .sellerProductImagesSaved &&
                                                (item?.mockupFiles === null ||
                                                  (item?.mockupFiles &&
                                                    item?.mockupFiles.length <
                                                      3)) &&
                                                order?.labelPdf === null &&
                                                item?.label ===
                                                  'waitingPayment' && (
                                                  <button
                                                    className="bg-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                    onClick={() => {
                                                      setSelectedProductId(
                                                        item.seller_product.id
                                                      );
                                                      setSelectedOrderItemId(
                                                        Number(item.id)
                                                      );
                                                      setIsDesignModalOpen(
                                                        true
                                                      );
                                                      setIsUpdate(false);
                                                      setIsImageSaved(
                                                        item.seller_product
                                                          ?.sellerProductImagesSaved
                                                      );
                                                      setIsCustom(false);
                                                    }}
                                                  >
                                                    <DocumentPlusIcon
                                                      color="white"
                                                      width={24}
                                                    />
                                                    <p className="text-white text-xs">
                                                      Ekle
                                                    </p>
                                                  </button>
                                                )}
                                            </div>
                                          )} */}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="w-32">
                                  {order?.seller_order_items[0].label !==
                                    'waitingMatch' &&
                                    order?.seller_order_items[0].label !==
                                      'waitingPayment' && (
                                      <Link
                                        id="message"
                                        target="_blank"
                                        to={`/messages?to=${order?.seller_order_items[0]?.supplier_order.supplier.userId}&name=${order?.seller_order_items[0]?.supplier_order.supplier.name}`}
                                        // &name=${order?.seller_order.user.firstName}

                                        className="w-full mb-2 block text-center text-xs text-[#1D4ED8] border-2 px-1 py-1 border-[#1D4ED8] rounded-md"
                                      >
                                        Mesaj Gönder
                                      </Link>
                                    )}
                                  {order.labelPdf === null && (
                                    <button
                                      onClick={() => {
                                        setSelectedOrder(order);
                                        setIsNoteModalOpen(true);
                                      }}
                                      className="w-full text-center text-xs text-white border-2 px-1 py-1 border-[#1D4ED8] bg-[#1D4ED8] rounded-md"
                                    >
                                      Not Ekle
                                    </button>
                                  )}

                                  <button
                                    onClick={() =>
                                      hideOrder(
                                        order.seller_order_items.map(
                                          (item: any) => Number(item.id)
                                        )
                                      )
                                    }
                                    className="w-full text-center text-xs text-white mt-2 border-2 px-1 py-1 border-[#6B7280] bg-[#6B7280] rounded-md"
                                  >
                                    {order.seller_order_items[0].label ===
                                    'hidden'
                                      ? 'Göster'
                                      : 'Gizle'}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {resendOrderItem && (
        <ResendModal
          isModalOpen={resendModal}
          setIsModalOpen={setResendModal}
          orderItems={resendOrderItem}
          getOrders={getOrders}
        />
      )}
      {resendOrder && (
        <ResendOrderModal
          isModalOpen={isResendModalOpen}
          setIsModalOpen={setIsResendModalOpen}
          order={resendOrder}
        />
      )}
      <DesignModal
        isCustom={isCustom}
        open={isDesignModalOpen}
        isImageSaved={isImageSaved}
        setOpen={setIsDesignModalOpen}
        selectedProductId={selectedProductId}
        selectedOrderItemId={selectedOrderItemId}
        getOrders={getOrders}
        setIsModalOpen={setIsUpdateModalOpen}
        isUpdate={isUpdate}
        addedFrom="ordersPage"
      />
      <AddDesignInfoModal
        isModalOpen={designAddInfoModal}
        setIsModalOpen={setDesignAddInfoModal}
        setIsDesignModalOpen={setIsDesignModalOpen}
        productId={productId}
      />
      {selectedEtsyOrder && (
        <UpdateOrderForm
          setIsDesignModalOpen={setIsDesignModalOpen}
          isModalOpen={isUpdateModalOpen}
          setSelectedProductId={setSelectedProductId}
          setIsModalOpen={setIsUpdateModalOpen}
          selectedOrder={selectedEtsyOrder}
          getOrders={getOrders}
          setIsUpdate={setIsUpdate}
          setIsCustom={setIsCustom}
          setSelectedOrder={setSelectedEtsyOrder}
        />
      )}

      <PaymentModal
        isModalOpen={isPaymentModalOpen}
        setIsModalOpen={setIsPaymentModalOpen}
        balance={balance}
        multiplier={multiplier}
        getWalletBalance={getWalletBalance}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        getOrders={getOrders}
        setIsWalletModalOpen={setIsWalletModalOpen}
      />
      <RejectReasonModal
        isModalOpen={rejectReasonModal}
        setIsModalOpen={setRejectReasonModal}
        reason={selectedReason}
      />
      <CancelOrderModal
        isModalOpen={isCancelModalOpen}
        setIsModalOpen={setIsCancelModalOpen}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        getOrders={getOrders}
      />

      <OrderMatchingModal
        isModalOpen={isModalOpen}
        queryParams={queryParams}
        page={page}
        cargoPrices={cargoPrices}
        setCargoPrices={setCargoPrices}
        setIsModalOpen={setIsModalOpen}
        selectedOrder={selectedOrder}
        getOrders={getOrders}
      />
      <WalletModal
        isModalOpen={isWalletModalOpen}
        setIsModalOpen={setIsWalletModalOpen}
        balance={balance}
        setEftModalOpen={setEftModalOpen}
        planId={planId}
        setPlanId={setPlanId}
        setDepositAmount={setDepositAmount}
        setIsStripeModalOpen={setIsStripeModalOpen}
      />
      <EFTModal
        isModalOpen={eftModalOpen}
        setIsModalOpen={setEftModalOpen}
        balance={balance}
        depositAmount={depositAmount}
        setIsWalletModal={setIsWalletModalOpen}
      />
      <AddNoteModal
        isModalOpen={isNoteModalOpen}
        getOrders={getOrders}
        setIsModalOpen={setIsNoteModalOpen}
        selectedOrder={selectedOrder}
      />
      <StripeModal
        setIsModalOpen={setIsStripeModalOpen}
        isModalOpen={isStripeModalOpen}
        balance={balance}
      />

      {selectedOrder && (
        <DocumentUploadModal
          isModalOpen={isDocumentModalOpen}
          setIsModalOpen={setIsDocumentModalOpen}
          orderItemId={selectedOrder}
          getOrders={getOrders}
        />
      )}
      <ToastContainer />
      <LoadingModal
        text="Label alınıyor..."
        isModalOpen={loadingLabel}
        setIsModalOpen={setLoadingLabel}
      />
    </div>
  );
};

export default MarketplaceOrderTable;
