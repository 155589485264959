import { Calendar, CloseCircle, Tree } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../utils/conditionalClasses';

export default function CampDiscountWarn() {
  const initialTargetDate = new Date('2024-09-05T10:00:00').getTime();
  const nextTargetDate = new Date('2024-10-12T10:00:00').getTime(); // Yeni tarih
  const [open, setOpen] = useState(true);
  const [campDates, setCampDates] = useState('18-19 Ocak');

  useEffect(() => {
    const now = new Date().getTime();

    if (now >= initialTargetDate && now < nextTargetDate) {
      setCampDates('18 Ocak');
    }
  }, []);

  return (
    <div
      className={classNames(
        open ? 'fixed' : 'hidden',
        'inset-0 z-10 flex items-center justify-center p-4'
      )}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />

      <div className="relative overflow-hidden bg-white rounded-[24px] px-[20px] py-[15px] lg:px-[60px] lg:py-[30px] text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg lg:min-h-[500px] lg:min-w-[550px] flex flex-col items-center justify-center gap-4">
        <div className="flex sm:gap-1 items-center justify-center text-[16px]">
          <CloseCircle
            className="absolute top-2 right-2 cursor-pointer"
            size={24}
            color="#F97316"
            onClick={() => setOpen(false)}
          />
          <p className="text-[#374151] text-center">
            <Tree
              variant="Bold"
              color="#F97316"
              className="sm:w-6 sm:h-6 inline"
            />
            Rexven tedarik sistemine bağlı mağazanız bulunmamaktadır.
          </p>
        </div>
        <div className="flex items-center justify-center sm:text-[24px] text-center font-semibold">
          <p className="text-[#374151]">
            Yeni tedarik sistemi hakkında bilgi almak ve{' '}
            <span className="relative inline-block z-10">
              {`Amazon Etsy`}
              <span className="absolute bottom-0 left-0 w-full h-2/5 bg-[#F97316] -z-10"></span>
            </span>
            <br />
            mağazalarınızı bağlamak için bilgi alın.
          </p>
        </div>
        <p className="text-sm text-[#374151] font-light text-center">
          <span className="font-bold">Not: </span> Tedarik sisteminde takı,
          tshirt, ahşap, deri, metal ürünler, hediyelik eşyalar gibi
          kategorilerde binlerce karlı ürün mevcuttur. Bu sisteme katıldığınızda
          siz sadece ürünleri listeler ve satış yaparsınız. Rexven sattığınız
          ürünleri sizin adınıza gönderir. Tüm mağazalarınızı tek bir panelden
          yönetirsiniz.
        </p>
        <Link
          type="button"
          className="rounded-[12px]  bg-[#1D4ED8] text-white py-3 px-6 sm:px-12 font-bold text-sm"
          to={
            'https://api.whatsapp.com/send?phone=905352051963&text=Merhaba%20Yüz%20yüze%20Amazon%20ve%20Etsy%20kampı%20hakkında%20bilgi%20almak%20istiyorum'
          }
          target="_blank"
        >
          Bilgi Al
        </Link>
        <div className="flex gap-1 items-center justify-center text-[16px]">
          <Calendar variant="Bold" color="#F97316" className="w-6 h-6" />
          <p className="text-[#374151]">
            <span className="font-bold">İstanbul Kampı Tarihi:</span>
            {` ${campDates}`}
          </p>
        </div>
        {/* <div className='w-full flex flex-col items-start justify-center gap-2'>
                    <div className='flex gap-1 items-center justify-center text-[16px]'>
                        <Calendar variant='Bold' color='#F97316' className='w-6 h-6' />
                        <p className='text-[#374151]'>
                            <span className='font-bold'> Tarih:</span>
                            {` ${campDates}`}
                        </p>
                    </div>
                    <div className='flex gap-1 items-start sm:items-center justify-center text-[16px]'>
                        <Location variant='Bold' color='#F97316' className='w-6 h-6' />
                        <p className='text-[#374151]'>
                            <span className='font-bold'> Yer:</span>
                            {` İstanbul`} </p>
                    </div>
                    <div className='flex gap-1 items-center justify-center text-[16px]'>
                        <People variant='Bold' color='#F97316' className='w-6 h-6' />
                        <p className='text-[#374151]'>
                            <span className='font-bold'> Kontenjan:</span>
                            {` 50 Kişi`} </p>
                    </div>
                </div> */}
        {/* Uncomment the following block to add background image */}
        {/* <div className="absolute -right-6 -bottom-8 w-[400px] h-[400px] -z-10">
                            <img
                                src='/images/camp.webp'
                                alt="Presentation"
                                className="w-full h-full object-cover mask-image opacity-60"
                                style={{
                                    WebkitMaskImage: `url(/images/campBg.webp)`,
                                    maskImage: `url(/images/campBg.webp)`,
                                    WebkitMaskSize: '100% 100%',
                                    maskSize: '100% 100%',
                                }}
                            />
                        </div> */}
      </div>
    </div>
  );
}
