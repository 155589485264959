import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, StarIcon } from '@heroicons/react/20/solid';
import httpClient from '../../utils/httpClient';
import alertNotification from '../../utils/alertNotification';
import { classNames } from '../../utils/conditionalClasses';

interface AddNoteModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrder: any;
  getOrders: () => void;
  // refetchData: () => void;
}
interface Product {
  id: number;
  title: string;
  images: string[];
}

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrder,
  getOrders,
  // refetchData,
}) => {
  // const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [note, setNote] = useState(
    selectedOrder?.seller_order_items[0].note ?? ''
  );
  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const handleAddNote = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      await httpClient.patch(`/api/v1/marketplace/seller/note`, {
        orderItemId: selectedOrder.seller_order_items[0].id,
        note: note,
      });

      alertNotification('success', 'Not ekleme işlemi başarılı');
      // refetchData();
      setIsModalOpen(false);
      setNote('');
      getOrders();
    } catch (error) {
      alertNotification('error', 'Not ekleme işlemi başarısız');
    }
  };
  useEffect(() => {
    if (selectedOrder && selectedOrder?.seller_order_items[0].note) {
      setNote(selectedOrder?.seller_order_items[0].note);
    }
  }, [selectedOrder]);

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl 3xl:max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
                >
                  Tedarikçiye Notunuzu Yazınız
                </Dialog.Title>
                {/* <div className="mt-2 flex px-8">
                  <div className="flex flex-col">
                    <div className="flex flex-row ">
                      <img
                        src={selectedOrder?.seller_order_items[0].seller_product_variant.images[0]}
                        alt="Order item"
                        className="w-20 h-20 3xl:w-20 3xl:h-20"
                      />
                      <p className="px-4 text-md">
                        {selectedOrder?.seller_order_items[0].seller_product_variant.name}
                      </p>
                    </div>
                  </div>
                </div> */}
                <div className="pt-8 px-8">
                  <textarea
                    className="w-full h-40 rounded-lg resize-none"
                    value={note}
                    onChange={handleNoteChange}
                  ></textarea>{' '}
                </div>
                {note.length > 360 && (
                  <p className="text-rexRed font-bold text-sm px-8">
                    Karakter limitini aştınız!
                  </p>
                )}
                <div className="flex justify-end w-full px-8">
                  <p>{note.length}/360</p>
                </div>
                <div className="flex justify-end px-8">
                  <button
                    type="button"
                    disabled={note.length === 0 || note.length > 360}
                    className={classNames(
                      note.length > 360 || note.length === 0
                        ? 'bg-lightGray'
                        : 'bg-[#eb6d20]',
                      'inline-flex justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-white focus:outline-none'
                    )}
                    onClick={handleAddNote}
                  >
                    Onayla
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddNoteModal;
