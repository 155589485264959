import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import alertNotification from '../../utils/alertNotification';
import marketplaceService from '../../services/marketplaceService';
import { InfoCircle } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';
import { useSearchParams } from 'react-router-dom';

interface PaymentModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrders: any[];
  getWalletBalance: () => void;
  balance: number;
  multiplier: number;
  setIsWalletModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOrders: React.Dispatch<React.SetStateAction<any[]>>;
  getOrders: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrders,
  balance,
  multiplier,
  setSelectedOrders,
  getOrders,
  getWalletBalance,
  setIsWalletModalOpen,
}) => {
  const [seacrhParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const calculateProductCost = (item: any) => {
    if (item.lineItemKey === null) {
      return Number(
        Number(
          Number(item.supplierOrderProductVariantPrice) * multiplier
        ).toFixed(2)
      );
    } else if (item.lineItemKey && !item.lineItemKey.includes('faulty')) {
      return Number(
        Number(
          Number(item.supplierOrderProductVariantPrice) * multiplier
        ).toFixed(2)
      );
    } else {
      return 0;
    }
  };

  const calculateDesignCost = (item: any) => {
    // If this category has not need a design price, just simply return 0
    if (!item.seller_product.supplierCategoryHasDesignCost) {
      return 0;
    }

    if (item.lineItemKey === null) {
      if (item.designs && item.designs.length > 1) {
        return (item.designs.length - 1) * 2;
      } else {
        return 0;
      }
    } else if (item.lineItemKey && !item.lineItemKey.includes('faulty')) {
      if (item.designs && item.designs.length > 1) {
        return (item.designs.length - 1) * 2;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  function calculateTotalCost(data: any) {
    return data.reduce((total: number, item: any) => {
      const itemTotal = item.seller_order_items.reduce(
        (sum: number, product: any) => {
          return (
            sum +
            calculateProductCost(product) * product.quantity +
            calculateDesignCost(product) * product.quantity
          );
        },
        0
      );

      const shipmentCost = item.seller_order_items.some((soi: any) =>
        soi.lineItemKey.includes('faulty')
      )
        ? 0
        : Number(item.shipmentCost);

      return total + itemTotal + shipmentCost;
    }, 0);
  }

  let totalCost = 0;
  if (selectedOrders) {
    totalCost = calculateTotalCost(selectedOrders);
  }

  const handleMakePayment = async () => {
    const orderIds: number[] = [];
    // selectedOrders.map((order) => {
    //   orderIds.push(Number(order.id));
    // });
    setLoading(true);
    selectedOrders.map((order) => {
      order.seller_order_items.map((item: any) => {
        orderIds.push(Number(item.id));
      });
    });

    try {
      await marketplaceService.buySupplierProduct(orderIds);
      alertNotification('success', 'Ödeme başarılı');
      getWalletBalance();
      getOrders();
      setIsModalOpen(false);
      setSelectedOrders([]);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl 3xl:max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-center py-4 leading-6 text-gray-900"
                ></Dialog.Title>
                <div className=" overflow-y-auto max-h-[25rem] space-y-2 rounded-2xl p-2">
                  {selectedOrders.map((selectedOrder, index: number) => (
                    <div
                      key={selectedOrder.id}
                      className="grid grid-cols-12 border border-indigo-400 rounded-xl"
                    >
                      {selectedOrder.seller_order_items.length > 0 &&
                        selectedOrder.seller_order_items.map((item: any) => (
                          <div className="col-span-8 grid grid-cols-8">
                            <div className="col-span-5">
                              <div className="flex justify-start items-center p-2">
                                <img
                                  src={
                                    item.seller_product.images[0]
                                      ? item.seller_product.images[0]
                                      : '/images/image-not-found.png'
                                  }
                                  alt="Order item"
                                  className="w-16 h-16 3xl:w-20 3xl:h-20"
                                />
                                <p className="px-4 text-sm">
                                  {item?.seller_product.title.substring(0, 60)}{' '}
                                  ...
                                </p>
                              </div>
                            </div>

                            <div className="col-span-3 text-sm px-2 flex items-center">
                              <p>
                                Ürün : ${calculateProductCost(item)}{' '}
                                {item.quantity > 1 ? `* ${item.quantity}` : ''}{' '}
                              </p>
                              {
                                <>
                                  {calculateDesignCost(item) > 0 ? (
                                    <>
                                      <span>+${calculateDesignCost(item)}</span>{' '}
                                      <InfoCircle
                                        color="red"
                                        size={18}
                                        id="toolTip"
                                        className="ml-1"
                                      />
                                      <Tooltip anchorSelect="#toolTip">
                                        İlave tasarım başına $2 ücret
                                        eklenmektedir.
                                      </Tooltip>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </>
                              }
                            </div>
                          </div>
                        ))}
                      <div className="col-span-2 flex items-center">
                        <p className="text-sm">
                          Kargo: $
                          {selectedOrder.seller_order_items.some((item: any) =>
                            item.lineItemKey.includes('faulty')
                          )
                            ? 0
                            : selectedOrder.shipmentCost.toFixed(2)}
                        </p>
                      </div>
                      <div className="col-span-2 px-2 flex items-center">
                        <div className="flex items-center">
                          {/* <XMarkIcon className="w-6 h-6" color="red" />{' '} */}
                          {selectedOrder.quantity}
                          <p className="h-10 w-16 mr-4 py-2 rounded-lg text-center font-medium text-[#2563EB]">
                            $
                            {(
                              selectedOrder.seller_order_items.reduce(
                                (acc: number, order: any) =>
                                  acc +
                                  calculateDesignCost(order) +
                                  calculateProductCost(order) * order.quantity,
                                0
                              ) +
                              (selectedOrder.seller_order_items.some(
                                (item: any) =>
                                  item.lineItemKey.includes('faulty')
                              )
                                ? 0
                                : selectedOrder.shipmentCost)
                            ).toFixed(2)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-start mt-8 items-center space-x-2">
                  <p>Cüzdan Bakiyesi: ${Number(balance).toFixed(2)} </p>
                  <button
                    className="bg-[#2563EB] px-2 py-2 text-white rounded-lg"
                    disabled={loading}
                    onClick={() => {
                      setIsWalletModalOpen(true);
                      setSearchParams('plan=600');
                      setIsModalOpen(false);
                    }}
                  >
                    Para Ekle
                  </button>
                  <p className="px-4 py-2 ml-2 text-headerPrimary text-md rounded-xl">
                    Toplam Ödeme: ${totalCost.toFixed(2)}
                  </p>
                  <button
                    onClick={() => handleMakePayment()}
                    disabled={loading}
                    className="bg-[#2563EB]  hover:bg-blue-700 text-white py-2 px-2 rounded-lg"
                  >
                    {loading ? 'Ödeme Yapılıyor' : 'Ödeme Yap'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PaymentModal;
